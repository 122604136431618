import React from "react";

const CreateCategoryForm = ({
  handleSubmit,
  handleOnChange,
  // handleAddCategory,
  // setCatedgoryData,
  categoryTitle,
  isUpdate,
  loader,
}) => {
  return (
    <div className="card-body">
      <form className="row g-3">
        <div className="col-12">
          <label className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            name="title"
            placeholder="Title name"
            value={categoryTitle}
            // onChange={(e) =>
            //   setCatedgoryData({
            //     ...catedgoryData,
            //     title: e.target.value,
            //   })
            // }
            onChange={(e) => {
              handleOnChange(e.target.value);
            }}
          />
        </div>

        <div className="col-12">
          <div className="d-grid">
            <button
              className="btn btn-primary"
              // onClick={(e) => handleAddCategory(e)}
              onClick={() => handleSubmit()}
              disabled={loader}
            >
              {isUpdate
                ? loader
                  ? "Updating"
                  : "Update Blog Category"
                : loader
                ? "Adding"
                : "Add Blog Category"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateCategoryForm;
