import React, { useEffect, useState } from "react";
import "../../assets/css/landingPage.css";
import { NavLink } from "react-router-dom";
import AutoComplete from "../autoComplete/index.tsx";

const CommonFirstSectionHeader = ({
  data,
  backgroundImage,
  backgroundImageUrl,
  clickHandler,
  inputnotshow,
}) => {
  const [homeRoute, setHomeRoute] = useState("");
  const [otherRoute, setOtherRoute] = useState("");
  const [userInput, setUserInput] = useState("");

  const ourServices = [
    "Gas Safety Certificate",
    "CO & Smoke Alarms",
    "Gas Leak Repairs",
    "Boiler Repairs",
    "Plumbing",
    "An emergency",
    "Boiler ",
    "Bathroom ",
    "Kitchen appliances",
    "Leaks ",
    "Painter",
    "Painting ",
    "Wallpapering ",
    "Carpentry ",
    "Plastering ",
    "Tiling  ",
    "Electrical",
    "Appliances ",
    "Fuse boards",
    "Lighting ",
    "Safety checks & certificates",
    "Alarm System and Security",
    "Smoke alarms",
    "Handyman",
    "Gutter Clean & Leak Inspection",
    "Sash Draught Proofing",
    "Draft Proofing External Doors",
    "Furniture Assembly Service",
    "Appliances Repairs",
    "Locksmith Services",
    "Plastering Services",
    "Window Repair Services",
    "Fence Repair Services",
    "Carpentry Services",
    "Tiler",
    "Bathroom Renovation",
    "Windows &doors",
    "Kitchens ",
    "Flooring ",
    "Fit new tiles",
    "Replace tiles",
    "Repair tiles",
    "Emergency issue",
    "Other",
  ];

  useEffect(() => {
    const splitdata = data?.text1?.split("|");
    setHomeRoute(splitdata[0]?.trim());
    setOtherRoute(splitdata[1]?.trim());
  }, [data]);

  return (
    <div>
      <div
        className={backgroundImageUrl === undefined ? `${backgroundImage}` : ""}
        style={
          backgroundImageUrl !== undefined
            ? {
                backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${backgroundImageUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }
            : {}
        }
      >
        <div className="container div_container_wrapper">
          <div
            className={`div_slide__content div_commonwrapper div_slide__content_padding_carosel `}
          >
            <div className="row">
              <div className="col-lg-12">
                <div className={`inner_div_div_slide__content `}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {data.text1 === "HOME | OUR BLOGS" ||
                    data.text1 === "HOME | OUR SERVICES" ||
                    data.text1 === "HOME  | ABOUT US" ? (
                      <>
                        <div className="d-flex" style={{ gap: "10px" }}>
                          <NavLink
                            className="fontSize12W4 colorWhite tex1_headercarsole_landing"
                            to={homeRoute === "HOME" ? "/" : ""}
                          >
                            {homeRoute}
                          </NavLink>
                          <div className="fontSize12W4 colorWhite tex1_headercarsole_landing">
                            |
                          </div>
                          <NavLink
                            className="fontSize12W4 colorWhite tex1_headercarsole_landing"
                            to={
                              otherRoute === "OUR SERVICES"
                                ? "/our-services"
                                : otherRoute === "OUR BLOGS"
                                ? "/our-blogs"
                                : otherRoute === "ABOUT US"
                                ? "/about-us"
                                : ""
                            }
                          >
                            {otherRoute}
                          </NavLink>
                        </div>
                      </>
                    ) : (
                      <p className="w-400 colorWhite tex1_headercarsole_landing mb-0">
                        {data.text1}
                      </p>
                    )}

                    <h1 className="w-600 colorWhite title_headercarsole_landing mb-0 mt-2">
                      {data.title1}
                    </h1>
                    <h1 className="w-600 colorWhite title_headercarsole_landing mb-0">
                      {data.title2}
                    </h1>
                    <h6
                      className={`w-600 colorWhite h2_headertext_bgimage text-center`}
                    >
                      {data.text2}
                    </h6>

                    {!inputnotshow && (
                      // <div
                      //   className="d-flex"
                      //   // style={{
                      //   //   display: "flex",
                      //   //   width: "85%",
                      //   // }}
                      // >
                      //   <div
                      //   // style={{
                      //   //   width: "50%",
                      //   // }}
                      //   >
                      //     <AutoComplete
                      //       suggestions={ourServices}
                      //       userInput={userInput}
                      //       setUserInput={setUserInput}
                      //     />
                      //   </div>
                      //   <div
                      //   // style={{
                      //   //   width: "30%",
                      //   // }}
                      //   >
                      //     <i
                      //       style={{
                      //         position: "absolute",
                      //         padding: 10,
                      //         color: "#29487d",
                      //       }}
                      //       className="bi bi-geo-alt"
                      //     ></i>
                      //     <input
                      //       className="banner_input"
                      //       placeholder="Postal Code"
                      //     />
                      //   </div>
                      //   <div
                      //   // style={{
                      //   //   width: "20%",
                      //   // }}
                      //   >
                      //     <button
                      //       className="btn_get_started fontSize12W6 colorWhite"
                      //       style={{
                      //         width: "100%",
                      //         height: 45,
                      //       }}
                      //       onClick={() => clickHandler({ userInput })}
                      //     >
                      //       Submit
                      //     </button>
                      //   </div>
                      // </div>
                      <div className="row">
                        {/* Autocomplete */}
                        <div className="col-6 p-0">
                          <AutoComplete
                            suggestions={ourServices}
                            userInput={userInput}
                            setUserInput={setUserInput}
                          />
                        </div>

                        {/* Postalcode */}
                        <div className="col-4 p-0">
                          <i
                            style={{
                              position: "absolute",
                              padding: 10,
                              color: "#29487d",
                            }}
                            className="bi bi-geo-alt"
                          ></i>
                          <input
                            className="banner_input"
                            placeholder="Postal Code"
                          />
                        </div>

                        {/* Button */}
                        <div className="col-2 p-0">
                          <button
                            className="btn_get_started fontSize12W6 colorWhite"
                            style={{
                              width: "100%",
                              height: 45,
                            }}
                            onClick={() => clickHandler({ userInput })}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                s
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonFirstSectionHeader;
