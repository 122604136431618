import React from "react";

const ContactUsFormTable = ({ itemOffset, currentItems }) => {
  return (
    <>
      <div className="table-responsive">
        <table className="table align-middle">
          <thead className="table-light">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Postal Code</th>
              <th>Service Requested</th>
              <th>Message</th>
              <th>Submitted On</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, index) => {
              const createDate = new Date(item.created_at);
              return (
                <tr key={item?.id}>
                  <td>{itemOffset + index + 1}</td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.name ? item?.name : "N/A"}
                      </h6>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.email ? item?.email : "N/A"}
                      </h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.phone_number ? item?.phone_number : "N/A"}
                      </h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.postal_code ? item?.postal_code : "N/A"}
                      </h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.service ? item?.service : "N/A"}
                      </h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.message ? item?.message : "N/A"}
                      </h6>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {createDate ? createDate.toDateString() : "N/A"}
                      </h6>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ContactUsFormTable;
