import React from "react";
import "../../../../assets/css/admin.css";

const AdminDashboard = () => {
  return (
    <div>
      <main className="page-content">Admin Dashboard Page</main>
    </div>
  );
};

export default AdminDashboard;
