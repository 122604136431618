import React from "react";
import Layout from "../layouts/index.tsx";
import CommonFirstSectionHeader from "../../components/commonFirstSectionHeader/index.tsx";
import SectionOurServices from "../../sections/sectionOurServices/index.tsx";
import "../../assets/css/ourServices.css";

const OurServicePage = () => {
  const dataimgHeaderOurServices = {
    text1: "HOME | OUR SERVICES",
    title1: "What Will You ",
    title2: "Get From Us Check It",
    text2:
      "Raising a heavy fur muff that covered the whole of her lower arm towards the viewer regor then turned to look out the window.",
  };

  const titledata = localStorage.getItem("title");
  return (
    <Layout>
      <div>
        <CommonFirstSectionHeader
          data={dataimgHeaderOurServices}
          backgroundImage={"bg-image-header-ourservices"}
          clickHandler={() => {}}
          inputnotshow={true}
        />
      </div>
      <div className="container div_container_wrapper">
        <div className="div_commonwrapper div_main_SectionOurServices">
          <SectionOurServices datafromHome={titledata} />
        </div>
      </div>
    </Layout>
  );
};

export default OurServicePage;
