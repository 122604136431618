import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { protectedRoutes, publicRoutes } from "./routes/routeMap";
import ScrollToTop from "./components/scrollToTop/index.tsx";
import { ToastContainer } from "react-toastify";
import { useAuth } from "./context/AuthContext.tsx";
import Adminlogin from "./pages/admin/adminLogin.tsx";
import AdminLayout from "./pages/layouts/adminLayout.tsx";
const App = () => {
  const { auth } = useAuth();
  console.log("AUTH ==> ", auth);
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {publicRoutes?.map((route, index) => (
            <Route
              key={`${route.path}-${index}`}
              path={route.path}
              element={route.element}
            />
          ))}
          {protectedRoutes?.map((route, index) => (
            <Route
              key={`${route.path}-${index}`}
              path={route.path}
              // element={<ProtectedRoute>{route.element}</ProtectedRoute>}
              element={
                auth ? (
                  <AdminLayout>{route.element}</AdminLayout>
                ) : (
                  <Adminlogin />
                )
              }
            />
          ))}
        </Routes>
        <ToastContainer />
      </ScrollToTop>
    </Router>
  );
};

export default App;
