import React from "react";
import testImage from "../../assets/images/Boiler.png";
import { useNavigate } from "react-router-dom";

const CardOurBlog = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`col-lg-3 col-md-4 col-sm-12 p-3 mb-3 me-3 div_card_follow div_card_ourblogs_landing`}
      onClick={() => {
        navigate(`our-blogs/${data.id}`, {
          state: {
            item: data,
          },
        });
      }}
      style={{ cursor: "pointer" }}
    >
      <div>
        <div className="div_image_card_topleft_text">
          <img
            className="image_card_ourblog"
            src={
              data.image_url
                ? `${process.env.REACT_APP_BACKEND_URL}${data.image_url}`
                : testImage
            }
            alt="image_not_available"
          />
        </div>
        <h4 className="mt-4">{data.title}</h4>
        <p
          className="w-500 color3e"
          style={{ maxHeight: 110, overflow: "hidden" }}
        >
          {data.short_description
            ? data.short_description.length < 550
              ? data.short_description
              : data.short_description.slice(0, 550) + "..."
            : "Short Description"}
        </p>
      </div>
    </div>
  );
};

export default CardOurBlog;
