import React from "react";

const BlogCategoryTable = ({
  currentItems,
  EditCategory,
  onDelete,
  loader,
  delData,
  itemOffset,
}) => {
  return (
    <>
      <div className="table-responsive">
        <table className="table align-middle">
          <thead className="table-light">
            <tr>
              <th>ID</th>
              <th>Blog Category</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, index) => {
              return (
                <tr key={item?.id}>
                  <td>{itemOffset + index + 1}</td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">{item?.title}</h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <div
                        className="text-warning cursor-pointer"
                        onClick={() => EditCategory(item)}
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </div>
                      {loader && delData?.id === item?.id ? (
                        "deleting...."
                      ) : (
                        <div
                          className="text-danger cursor-pointer"
                          onClick={() => onDelete(item)}
                        >
                          <i className="bi bi-trash-fill" />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BlogCategoryTable;
