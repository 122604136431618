import React from "react";

const IssuesFormTable = ({ itemOffset, currentItems }) => {
  return (
    <>
      <div className="table-responsive">
        <table className="table align-middle">
          <thead className="table-light">
            <tr>
              <th>#</th>
              <th>Issue ID</th>
              <th>Image</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Postal Code</th>
              <th>Message</th>
              <th>Reported On</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, index) => {
              const createDate = new Date(item.created_at);
              return (
                <tr key={item?.id}>
                  <td>{itemOffset + index + 1}</td>
                  <td>{item.id}</td>
                  <td>
                    <div
                      className="d-flex align-items-center gap-2"
                      style={{ width: 150, height: 150 }}
                    >
                      {item.image_url !== "" ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={`${process.env.REACT_APP_BACKEND_URL}${item.image_url}`}
                          alt="Reported issue"
                        />
                      ) : (
                        <h6>N/A</h6>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.name ? item?.name : "N/A"}
                      </h6>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.email ? item?.email : "N/A"}
                      </h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.phone_number ? item?.phone_number : "N/A"}
                      </h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.postal_code ? item?.postal_code : "N/A"}
                      </h6>
                    </div>
                  </td>

                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {item?.message ? item?.message : "N/A"}
                      </h6>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <h6 className="mb-0 product-title">
                        {createDate ? createDate.toDateString() : "N/A"}
                      </h6>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default IssuesFormTable;
