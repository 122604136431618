import React from "react";
import "../../assets/css/ourBlogs.css";
import CarouselPopular from "../../components/caroselPopular/index.tsx";

const PopularServices = () => {
  return (
    <div className="div_parent_sectionPopular div_commonwrapper ">
      <div className="d-flex justify-content-center" style={{ gap: "5px" }}>
        <h3 className="w-500 color3e mt-5 text-center">Browse Our</h3>
        <h3 className="w-500 color29 mt-5 text-center">
          Most Popular Categories
        </h3>
      </div>

      <div className="text-center d-flex justify-content-center mb-5">
        <h6 className="w-400 color3e mt-3 text-center div_text_browse_popular">
          Discover our most sought-after categories that capture the essence of
          our customers' preferences, offering a curated selection of services
          they love.
        </h6>
      </div>

      <div>
        <CarouselPopular />
      </div>
    </div>
  );
};

export default PopularServices;
