import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";

const ReactPagination = ({
  setCurrentItems,
  allDataArray,
  itemsPerPage,
  allData,
  itemOffset,
  setItemOffset,
}) => {
  const [pageCount, setPageCount] = useState(0);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allData?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(allDataArray?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(allDataArray?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, allDataArray]);
  return (
    <ReactPaginate
      previousLabel={"Prev"}
      nextLabel={"Next"}
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      activeClassName={"active"}
    />
  );
};

export default ReactPagination;
