import React from "react";
import { useNavigate } from "react-router-dom";
// Images
import imageserviceweprovide from "../../assets/images/image-section-weprovide.png";
import cardWeprovideimage1 from "../../assets/images/card-weprovide-1.png";
import cardWeprovideimage2 from "../../assets/images/card-weprovide-2.png";
import cardWeprovideimage3 from "../../assets/images/card-weprovide-3.png";
import cardWeprovideimage4 from "../../assets/images/card-weprovide-4.png";

const ServiceWeProvide = () => {
  const navigate = useNavigate();

  const cardWeProvide = [
    {
      img: cardWeprovideimage1,
      title: "Gas & Heating",
      text: "Stay warm and cozy with our exceptional gas and heating services. Our expert team is committed to delivering unparalleled comfort and efficiency, ensuring your home is a haven of warmth all year round.",
    },
    {
      img: cardWeprovideimage2,
      title: "Plumbing",
      text: "Plumbing is a vital aspect of any home or establishment, ensuring smooth operations and functionality. Our expert services provide reliable solutions to keep your plumbing systems running seamlessly, so you can focus on what truly matters.",
    },
    {
      img: cardWeprovideimage3,
      title: "Handyman",
      text: "Experience the convenience and expertise of our handyman services, as we diligently work to address your household needs, ensuring your satisfaction with every project.",
    },
    {
      img: cardWeprovideimage4,
      title: "Appliances",
      text: "Experience exceptional appliance servicing that goes beyond expectations. Our skilled technicians are committed to providing top-notch solutions, ensuring your appliances operate flawlessly, bringing convenience and peace of mind to your daily life.",
    },
  ];
  return (
    <div className="div_parent_section div_main_section_weprovide div_commonwrapper mt-5 mb-5 ">
      <div className="row div_firstrow_section_weprovide">
        <div className="col-xl-6 col-lg-12">
          <div className="row div_secondrow_section_weprovide">
            {cardWeProvide?.map((item, index) => (
              <div
                key={index}
                className="d-flex col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center justify-content-center"
              >
                <div
                  className="parent_div_card_weprovide"
                  onClick={() => {
                    navigate("/our-services", {
                      state: {
                        item,
                      },
                    });
                  }}
                >
                  <div className="div_card_image_weprovide">
                    <img
                      alt="services"
                      className="img_card_weproivde"
                      src={item.img}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  <div
                    className="div_subcard_weproivde text-center"
                    style={{ cursor: "pointer" }}
                  >
                    <h4 className="w-500 color3e">{item.title}</h4>
                    <h6 className="w-400 color97 mt-13 p_text_card_weprovide">
                      {item.text}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-xl-6 col-lg-12">
          <div className="row d-flex  div_title_service_we_text">
            <div>
              <h6 className="w-400 color3e">Our Services</h6>
              <h3 className="w-600 color3e">
                Services We<span className="span_easy_step"> Provide</span>
              </h3>
              <h6 className={`div_text3 w-400  color3e mt-4 widthtext2`}>
                With expertise in gas and heating systems, we offer
                comprehensive services to keep your home warm and safe,
                including installations, repairs, and regular maintenance,
                delivering optimal comfort and peace of mind. Our skilled
                handyman professionals are here to tackle a wide range of
                household projects, from minor repairs to renovations, providing
                efficient and high-quality solutions.
              </h6>
              <img
                alt="services"
                className="mt-4 img_imageserviceweprovide"
                src={imageserviceweprovide}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceWeProvide;
