import React, { useState } from "react";
// import logo from "../../assets/images/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
// import { ToastContainer } from "react-toastify";
import makeToast from "../../components/toast/index.tsx";
import { useAuth } from "../../context/AuthContext.tsx";
import { LoginUser } from "../../helpers/api-util.ts";

const Adminlogin = () => {
  const logo = require("../../assets/images/logo.png");
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [inputValue, setInputvalue] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    let name = e?.target?.name;
    let value = e?.target?.value;
    setInputvalue({ ...inputValue, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (inputValue.email !== "" && inputValue.password !== "") {
      LoginUser(inputValue)
        .then((res: any) => {
          console.log("[RES] - LOGIN ", res);
          if (res.user) {
            const authRes = {
              user: res.user,
              token: res.token,
            };
            setAuth(authRes);
            makeToast("success", "Logged in succesfully");
            navigate("/admin/dashboard");
          }
        })
        .catch((err) => {
          console.log("[ERR] - LOGIN ", err);
          makeToast(
            "error",
            err.message ? err.message : "Something went wrorng."
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      makeToast("error", "Kindly fill all the fileds");
    }
  };

  return (
    <>
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-4">
                <NavLink
                  to="/"
                  className="logo d-flex align-items-center gap-3 w-auto"
                  style={{ textTransform: "none", textDecoration: "none" }}
                >
                  <img src={logo} alt="logo" style={{ width: "100px" }} />
                </NavLink>
              </div>

              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">
                      Admin Panel
                    </h5>
                    <p className="text-center small">
                      Enter your email & password to login
                    </p>
                  </div>

                  <form className="row g-3 needs-validation" noValidate>
                    <div className="col-12 ">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">@</span>
                        <input
                          id="email"
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="abc@gamil.com"
                          value={inputValue.email}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        id="password"
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="**********"
                        value={inputValue.password}
                        onChange={(e) => handleChange(e)}
                        required
                      />
                    </div>

                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 mb-4 mt-3"
                        type="button"
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <ToastContainer /> */}
        </div>
      </section>
    </>
  );
};

export default Adminlogin;
