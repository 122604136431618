import React, { useState } from "react";
import CommonInput from "../../../components/commonInput/index.tsx";
import { useRef } from "react";
import { submitReportIssueForm } from "../../../helpers/api-util.ts";
import makeToast from "../../../components/toast/index.tsx";

const ReportAnIssueForm = () => {
  const inputRef = useRef(null);

  const [loaderContact, setLoaderContact] = useState(false);

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    phone_number: "",
    postal_code: "",
    message: "",
    image: "",
  });
  const [tempimagename, settempimagename] = useState("");

  const handleInputChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let value = e.target.value;

    if (name === "phone_number") {
      setInputValue({ ...inputValue, [name]: value.replace(/\D/g, "") });
    } else {
      setInputValue({ ...inputValue, [name]: value });
    }
  };

  const handleSubmit = async () => {
    setLoaderContact(true);

    if (
      inputValue.name !== "" &&
      inputValue.email !== "" &&
      inputValue.phone_number !== "" &&
      inputValue.postal_code !== "" &&
      inputValue.message !== ""
    ) {
      const formData = new FormData();
      formData.append("name", inputValue.name);
      formData.append("email", inputValue.email);
      formData.append("phone_number", inputValue.phone_number);
      formData.append("postal_code", inputValue.postal_code);
      formData.append("message", inputValue.message);
      if (inputValue.image !== "") {
        formData.append("image", inputValue.image);
      }

      submitReportIssueForm(formData)
        .then((res) => {
          console.log("[RES] : SUBMIT REPORT ISSUE FORM ==> ", res);
          makeToast("success", "Your form was submitted succesfully.");
          setInputValue({
            name: "",
            email: "",
            phone_number: "",
            postal_code: "",
            message: "",
            image: "",
          });
          settempimagename("");
        })
        .catch((err) => {
          console.log("[ERR] : SUBMIT REPORT ISSUE FORM ==> ", err);
          makeToast("error", "Something went wrong. Try again later!");
        })
        .finally(() => {
          setLoaderContact(false);
        });
    } else {
      makeToast("error", "Kindly fill all the fields");
      setLoaderContact(false);
    }
  };
  return (
    <div className="parent_div_form ">
      <form>
        <div className="d-flex flex-column inner_div_form">
          <CommonInput
            type={"text"}
            name={"name"}
            placeholder={"Enter Your Name"}
            value={inputValue.name}
            handleChangeValue={handleInputChange}
            inputstylecontactus="color3e input_field_contact_padding"
          />
          <CommonInput
            type={"email"}
            name={"email"}
            placeholder={"Enter Your Email"}
            value={inputValue.email}
            handleChangeValue={handleInputChange}
            inputstylecontactus="color3e input_field_contact_padding"
          />
          <CommonInput
            type={"text"}
            name={"phone_number"}
            placeholder={"Enter Your Phone Number"}
            value={inputValue.phone_number}
            handleChangeValue={handleInputChange}
            inputstylecontactus="color3e input_field_contact_padding"
          />
          <CommonInput
            type={"text"}
            name={"postal_code"}
            placeholder={"Enter Your postcode"}
            value={inputValue.postal_code}
            handleChangeValue={handleInputChange}
            inputstylecontactus="color3e input_field_contact_padding"
          />

          <textarea
            className="input_field_contact fontSize14W4 color3e input_field_contact_padding "
            id="description"
            name="message"
            rows={10}
            cols={50}
            placeholder="Type Your Message Here.."
            style={{ padding: "17px 32px" }}
            value={inputValue.message}
            onChange={handleInputChange}
          />

          <div className="upload-btn-wrapper-report  btn_wrapper_upload_image_report">
            <div className=" btn_upload_imagename_report">
              <div className="btn_text_report">
                <p className="color3e mb-0">Choose File</p>
              </div>
              <div className="div_selected_filename_report">
                <p className="colorb7 mb-0">
                  {tempimagename !== "" ? tempimagename : "No File Selected"}
                </p>
              </div>
            </div>
            <input
              type="file"
              name="image"
              id="image"
              accept="image/png, image/jpeg"
              ref={inputRef}
              onChange={(e) => {
                setInputValue({
                  ...inputValue,
                  [e?.target?.name]: e?.target?.files[0],
                });
                settempimagename(e?.target?.files[0].name);
              }}
            />
          </div>

          <div className="d-flex justify-content-center mt-5 div_btn_sumit_btnreservation_report">
            <button
              type="button"
              disabled={loaderContact}
              className={`commonbtnsubsmit fontSize16W5  btn_sumit_report commombtnsumitreport colorWhite`}
              onClick={handleSubmit}
            >
              {loaderContact ? (
                <div className="d-flex justify-content-center">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <p className="mb-0 ms-2">Please wait ...</p>
                </div>
              ) : (
                "Send Message"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportAnIssueForm;
