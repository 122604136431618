import axios from "axios";
import { ILogin } from "./interfaces.ts";

export const getRequest = (url: string, token: null | string = null) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    axios
      .get(url, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if ("response" in error) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const postRequest = (
  url: string,
  data: any,
  token: null | string = null
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .post(url, data, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if ("response" in error) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const patchRequest = (
  url: string,
  data: any,
  token: null | string = null
) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .put(url, data, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if ("response" in error) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
};

export const deleteRequest = (url: string, token: null | string = null) => {
  return new Promise((resolve, reject) => {
    const headers = {
      headers: {
        Accept: "application/json",
      },
    };

    if (token) {
      headers.headers["Authorization"] = `Bearer ${token}`;
    }

    axios
      .delete(url, headers)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if ("response" in error) {
          reject(error.response);
        } else {
          reject(error);
        }
      });
  });
};

// LOGIN API
export const LoginUser = (data: ILogin) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/login`;
  return postRequest(url, data);
};

// BLOGS APIS
export const getAllBlogs = () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs`;
  return getRequest(url);
};

export const createBlog = (token: string, data: any) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs`;
  return postRequest(url, data, token);
};

export const updateBlog = (token: string, data: any, id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs/${id}`;
  return patchRequest(url, data, token);
};

export const deleteBlog = (token: string, id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs/${id}`;
  return deleteRequest(url, token);
};

// CATEGORIES APIS
export const getAllCategories = () => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs_cat`;
  return getRequest(url);
};

export const getCategoryById = (id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs_cat/${id}`;
  return getRequest(url);
};

export const createCategory = (token: string, data: any) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs_cat`;
  return postRequest(url, data, token);
};

export const updateCategory = (token: string, data: any, id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs_cat/${id}`;
  return patchRequest(url, data, token);
};

export const deleteCategory = (token: string, id: number) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/blogs_cat/${id}`;
  return deleteRequest(url, token);
};

// CONTACT US APIS
export const getAllContactUsForms = (token: string) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/contact_us`;
  return getRequest(url, token);
};

export const submitContactUsForm = (data: any) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/contact_us`;
  return postRequest(url, data);
};

// ISSUES APIS
export const getAllIssues = (token: string) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/report_issue`;
  return getRequest(url, token);
};

export const submitReportIssueForm = (data: any) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/report_issue`;
  return postRequest(url, data);
};
