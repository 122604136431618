import React from "react";
import Ricon from "../../assets/images/R Icon.png";

const CommonTabContent = ({ handleOpenModal, data }) => {
  return (
    <div>
      <div className="row">
        {data?.map((item, index) => (
          <div
            key={index}
            className="col-xl-3 col-lg-4 col-md-4 col-sm-6 div_grid_col_card_ourservices"
            onClick={() => handleOpenModal(item)}
            style={{ cursor: "pointer" }}
          >
            <div className="div_card_ourservices mb-3">
              <img
                className="image_card_ourservices"
                src={item?.img ? item?.img : Ricon}
                alt="imagae"
                style={{ height: !item.img ? "175px" : "" }}
              />
              <h5 className="w-500 color3e mt-4 text-center">{item.title}</h5>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonTabContent;
