import React, { useEffect, useState } from "react";
import { deleteBlog, getAllBlogs } from "../../../../../helpers/api-util.ts";
import ReactPagination from "../../../../../components/reactPagination/index.tsx";
import BlogCard from "../../../../../components/blogsCard/index.tsx";
import { useAuth } from "../../../../../context/AuthContext.tsx";
import Swal from "sweetalert2";
import "../../../../../assets/css/admin.css";
import makeToast from "../../../../../components/toast/index.tsx";

const ViewBlogList = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const [allBlogList, setAllBlogList] = useState([]);
  const [filteredData, setFilteredData] = useState(allBlogList);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 4;
  const { token } = useAuth();

  const onDelete = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This blog will be deleted permanetly. You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBlog(token ? token : "", id)
          .then((res) => {
            console.log("[RES] : DELETE BLOG ==> ", res);
            makeToast("success", "Blog Succesfully Deleted!");
            fetchAllBlogs();
          })
          .catch((err) => {
            console.log("[ERR] : DELETE BLOG ==> ", err);
            makeToast(
              "error",
              err.message ? err.message : "Something went wrong. Try again!"
            );
          });
      }
    });
  };

  const fetchAllBlogs = () => {
    getAllBlogs()
      .then((res) => {
        console.log("[RES] - GET ALL BLOGS ==> ", res);
        setAllBlogList(res);
        setFilteredData(res);
        setCurrentItems(res);
      })
      .catch((err) => {
        console.log("[ERR] - GET ALL BLOGS ==> ", err);
      });
  };

  useEffect(() => {
    fetchAllBlogs();
  }, []);

  return (
    <div>
      <main className="page-content">
        <div className="d-flex flex-column col-12">
          <h2>View All Blogs</h2>
          <div
            // className="shadow-none w-100"
            style={{ height: "max-content", display: "flex", flexWrap: "wrap" }}
          >
            {currentItems.length ? (
              currentItems.map((item) => {
                return <BlogCard data={item} onDelete={onDelete} />;
              })
            ) : (
              <>
                <h2>No Blogs Uploaded</h2>
              </>
            )}
            <div className="stackPagination">
              <ReactPagination
                setCurrentItems={setCurrentItems}
                allDataArray={filteredData}
                itemsPerPage={itemsPerPage}
                allData={allBlogList}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewBlogList;
