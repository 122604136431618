import React from "react";

const ViewCategoryList = () => {
  return (
    <div>
      <main className="page-content">
        <div>This is View Category Page</div>
      </main>
    </div>
  );
};

export default ViewCategoryList;
