import React from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { IAuth, IAuthContext } from "../helpers/interfaces.ts";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext<IAuthContext>({
  auth: null,
  token: null,
  setAuth: () => {},
  logout: () => {},
});

export function AuthContextProvider({ children }: { children: any }) {
  const [mounted, setMounted] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [auth, setAuth] = useState<IAuth | null>(null);
  // const navigate = useNavigate();

  useEffect(() => {
    console.log("======= APP MOUNTED =======");
    const localStorageAuth = localStorage.getItem("auth");
    if (localStorageAuth) {
      try {
        setAuth(JSON.parse(localStorageAuth));
      } catch (error) {
        console.error("[ERR - Auth Context]: ", error);
      }
    }
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      if (auth) {
        setToken(auth.token);
        localStorage.setItem("auth", JSON.stringify(auth));
      } else {
        setToken(null);
        localStorage.removeItem("auth");
      }
    }
  }, [auth, mounted]);

  const logoutHandler = () => {
    setAuth(null);
  };

  const context = useMemo(
    () => ({
      auth,
      token,
      setAuth,
      logout: logoutHandler,
    }),
    [auth, token]
  );

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export default AuthContext;

export const useAuth = () => {
  return useContext(AuthContext);
};
