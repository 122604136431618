import React, { useEffect, useState } from "react";
import Layout from "../layouts/index.tsx";
import CommonFirstSectionHeader from "../../components/commonFirstSectionHeader/index.tsx";
import RightSideCol from "../../components/rightSideCol/index.tsx";
import {
  getAllBlogs,
  getAllCategories,
  getCategoryById,
} from "../../helpers/api-util.ts";
import BlogCard from "../../components/blogsCard/index.tsx";

const OurBlogsPage = () => {
  const [blogCategories, setBlogCategories] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);

  useEffect(() => {
    getAllCategories()
      .then((res) => {
        let cat_arry = [{ id: 0, title: "All" }];
        console.log("[RES] - GET ALL CATEGORIES ==> ", res);
        const new_arr = cat_arry.concat(res);
        console.log("NEW ARR ==> ", new_arr);
        setBlogCategories(new_arr);
      })
      .catch((err) => {
        console.log("[ERR] - GET ALL CATEGORIES ==> ", err);
      });
    getAllBlogs()
      .then((res) => {
        console.log("[RES] = GET ALL BLOGS ==> ", res);
        setLatestBlogs(res.slice(0, 3));
        setRelatedBlogs(res.slice(0, 3));
        setAllBlogs(res);
      })
      .catch((err) => {
        console.log("[ERR] - GET ALL BLOGS ==> ", err);
      });
  }, []);

  const handleCategoryClick = (index, item) => {
    setActiveTab(index);
    if (index === 0) {
      getAllBlogs()
        .then((res) => {
          console.log("[RES] = GET ALL BLOGS ==> ", res);
          setAllBlogs(res);
        })
        .catch((err) => {
          console.log("[ERR] - GET ALL BLOGS ==> ", err);
        });
    } else {
      getCategoryById(item.id)
        .then((res) => {
          console.log("[RES] : GET CAT BY ID ==> ", res);
          setAllBlogs(res.blogs);
        })
        .catch((err) => {
          console.log("[ERR] : GET ALL CAT BY ID ==> ", err);
        });
    }
  };
  return (
    <Layout>
      <div>
        <CommonFirstSectionHeader
          data={{
            text1: "HOME | OUR BLOGS",
            title1: "Welcome to",
            title2: "  Our Blog",
            text2: `Dive into a world of knowledge with our captivating blog posts, where insights meet inspiration. Unveil the latest trends, explore thought-provoking topics, and join the conversation that fuels minds. Discover the power of learning as we share stories that inform, entertain, and ignite curiosity.`,
          }}
          backgroundImage={"bg-image-header-ourblogs"}
          inputnotshow={true}
          clickHandler={() => {}}
        />
      </div>

      <div className="container div_container_wrapper">
        <div className="div_commonwrapper ">
          <div className="row mt-5 mb-5">
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <div>
                <h4>Blog-Categories</h4>
              </div>
              <div className="pt-5 pb-5">
                {blogCategories.length === 0 ? (
                  <div>
                    <h3 className="color3e w-400">No data Found </h3>
                  </div>
                ) : (
                  <div
                    className="d-flex flex-column align-items-baseline"
                    style={{ gap: "40px" }}
                  >
                    {blogCategories?.map((item, index) => {
                      return (
                        <div
                          key={item.id}
                          className={`div_tabs_heading`}
                          onClick={() => handleCategoryClick(index, item)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="">
                            <h5
                              className={`w-500 h4_text_tab_common text-center  ${
                                activeTab === item?.id ? "color29" : "color97"
                              }`}
                            >
                              {item?.title}
                            </h5>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12">
              <div className="row">
                <div className="col-xl-7 col-lg-6">
                  <div>
                    {
                      /* <SectionTabsOurBlogs
                      activeTab={activeTab}
                      activeTabTitle={activeTabTitle}
                      arraydata={selectortabContentdata}
                      handleNvaigateToNext={handleNvaigateToNext}
                    /> */
                      allBlogs.length > 0 ? (
                        allBlogs.map((blog, index) => (
                          <BlogCard key={index} data={blog} dashboard={false} />
                        ))
                      ) : (
                        <div>
                          <h3 className="color3e w-400">No data Found </h3>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <div className="d-flex flex-column" style={{ gap: "70px" }}>
                    <RightSideCol
                      title={"Recent Articles"}
                      data={relatedBlogs}
                    />
                    <RightSideCol title={"Latest News"} data={latestBlogs} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurBlogsPage;
