import React from "react";
import { useNavigate } from "react-router-dom";
import CommonButton from "../commonButton/index.tsx";

const RightSideCol = ({ title, data }) => {
  const navigate = useNavigate();
  const svgicon = (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8558 4.31195L8.71012 0.16625C8.59842 0.0583681 8.44882 -0.00132699 8.29354 2.23883e-05C8.13825 0.00137177 7.98971 0.0636575 7.87991 0.173464C7.7701 0.283271 7.70781 0.431813 7.70646 0.587097C7.70511 0.742381 7.76481 0.891983 7.87269 1.00368L11.0074 4.13843H0.592243C0.435171 4.13843 0.284531 4.20082 0.173464 4.31189C0.0623969 4.42296 0 4.5736 0 4.73067C0 4.88774 0.0623969 5.03838 0.173464 5.14945C0.284531 5.26052 0.435171 5.32291 0.592243 5.32291H11.0074L7.87269 8.45766C7.81613 8.51229 7.77101 8.57764 7.73997 8.6499C7.70893 8.72215 7.69259 8.79987 7.69191 8.8785C7.69123 8.95714 7.70621 9.03513 7.73599 9.10791C7.76577 9.1807 7.80974 9.24682 7.86535 9.30243C7.92096 9.35804 7.98708 9.40201 8.05987 9.43179C8.13265 9.46157 8.21064 9.47655 8.28928 9.47587C8.36791 9.47519 8.44563 9.45885 8.51788 9.42781C8.59014 9.39677 8.65549 9.35165 8.71012 9.29509L12.8558 5.14938C12.9669 5.03832 13.0292 4.88771 13.0292 4.73067C13.0292 4.57363 12.9669 4.42302 12.8558 4.31195Z"
        fill="white"
      />
    </svg>
  );
  return (
    <div>
      <h5 className=" w-500  color3e">{title}</h5>
      <div className="d-flex flex-column mt-4" style={{ gap: "15px" }}>
        {data?.map((item) => (
          <div key={item.id} className="div_card_recentarticle d-flex ">
            <div className="row">
              <div className="col-lg-4 col-md-12  col-sm-12">
                <div className="d-flex justify-content-center mb-2">
                  <img
                    className="img_imageRecentCard1"
                    src={`${process.env.REACT_APP_BACKEND_URL}${item.image_url}`}
                    alt="imageRecentCard1"
                  />
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="left_section_card_recentarticel">
                  <h4 className="w-500 color3e">{item?.title}</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.short_description?.slice(0, 200) + "...",
                    }}
                  ></div>
                  <div className="d-flex justify-content-between align-items-center mt-2 mb2 gap10">
                    <div>
                      <CommonButton
                        text={"Read More"}
                        stylediv={"btn_readmore_recentarticle_card"}
                        svgicon={svgicon}
                        fontSizebtncommon={"fontSize12W4"}
                        handleClick={() => {
                          navigate(`/our-blogs/${item.id}`, {
                            state: {
                              item,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="div_p_date_cardleft_recent">
                      <p className="fontSize10W5 color3e m-0 p_date_cardleft_recent">
                        {item.date}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RightSideCol;
