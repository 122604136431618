import React from "react";
import TextEditorQuill from "../../pages/admin/adminPages/textEditor/index.tsx";
const CreateBlogForm = ({
  handleSubmit,
  setBlogData,
  blogData,
  isUpdate,
  loader,
  categories,
  handleChangeEditor,
  editorState,
  setIsupdateImage,
  isupdateImage,
}) => {
  console.log("EDIT BLOG DATA ==> ", blogData);
  return (
    <form className="row g-3">
      <div className="col-12">
        <div className="d-flex flex-column align-items-center">
          <div className="upload-btn-wrapper testbtn product-image-shadow">
            <div className="btn_IconButton testbtn">
              {blogData?.tempImage !== "" ? (
                <img
                  src={
                    isUpdate
                      ? isupdateImage
                        ? blogData?.tempImage
                        : `${process.env.REACT_APP_BACKEND_URL}${blogData.image_url}`
                      : blogData?.tempImage
                  }
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt="Upload.."
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i
                    className="bi bi-image-fill text-secondary"
                    style={{ fontSize: 50 }}
                  ></i>
                  <div style={{ fontSize: 20 }} className="text-secondary">
                    Upload Image
                  </div>
                </div>
              )}
            </div>
            <input
              className="form-control testbtn"
              type="file"
              name="image"
              id="image"
              accept="image/png, image/jpeg"
              multiple
              onChange={(event) => {
                setBlogData({
                  ...blogData,
                  [event.target.name]: event.target.files[0],
                  tempImage: URL.createObjectURL(event.target.files[0]),
                });
                if (isUpdate) {
                  if (event.target.files[0]) {
                    setIsupdateImage(true);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      {categories && (
        <div className="col-12">
          <label className={"form-label"}>Blog Category</label>
          <select
            className="form-select"
            onChange={(e) => {
              setBlogData({
                ...blogData,
                blog_category_id: e.target.value,
              });
            }}
          >
            <option
              key={0}
              value={0}
              disabled={true}
              selected={blogData?.blog_category_id ? false : true}
            >
              Select Blog Category
            </option>
            {categories.map((item) => {
              return (
                <option
                  key={item?.id}
                  value={item?.id}
                  selected={
                    blogData?.blog_category_id === item?.id ? true : false
                  }
                >
                  {item?.title}
                </option>
              );
            })}
          </select>
        </div>
      )}
      <div className="col-12">
        <label className="form-label">Blog Title</label>
        <input
          type="text"
          className="form-control"
          name="blogTitle"
          placeholder="Write Blog Title"
          value={blogData.title}
          onChange={(e) =>
            setBlogData({
              ...blogData,
              title: e.target.value,
            })
          }
        />
      </div>
      <div className="col-12">
        <label className="form-label">Blog Short Description</label>
        <input
          type="text"
          className="form-control"
          name="title"
          placeholder="Write Blog Short Description"
          value={blogData.short_description}
          onChange={(e) =>
            setBlogData({
              ...blogData,
              short_description: e.target.value,
            })
          }
        />
      </div>
      <div className="col-12">
        <label className="form-label">Blog Content</label>
        <div>
          <TextEditorQuill
            value={editorState}
            handleChangeEditor={handleChangeEditor}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="d-grid">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={loader}
          >
            {isUpdate
              ? loader
                ? "Updating"
                : "Update Blog Content"
              : loader
              ? "Adding"
              : "Add Blog Content"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CreateBlogForm;
