import React, { useState, useEffect } from "react";
import ReactPagination from "../../../../components/reactPagination/index.tsx";
import { useAuth } from "../../../../context/AuthContext.tsx";
import { getAllIssues } from "../../../../helpers/api-util.ts";
import IssuesFormTable from "../../../../components/issuesFormTable/index.tsx";

const ViewIssuesPage = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const [allIssueList, setAllIssueList] = useState([]);
  const [filteredData, setFilteredData] = useState(allIssueList);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const itemsPerPage = 5;
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      getAllIssues(token)
        .then((res) => {
          console.log("[RES] : GET ALL ISSUES FORMS ==> ", res);
          setCurrentItems(res);
          setAllIssueList(res);
          setFilteredData(res);
        })
        .catch((err) => {
          console.log("[ERR] : GET ALL ISSUES FORMS ==> ", err);
        });
    }
  }, [token]);

  const filterSearchResult = (e) => {
    const searchKeyword = e.target.value;
    setSearchKeyword(searchKeyword);
    const newDataArray = filteredData
      .filter((data) => {
        if (
          data.id?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          data.name?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          data.email?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          data.phone_number
            ?.toLowerCase()
            .includes(searchKeyword.toLowerCase()) ||
          data.message?.toLowerCase().includes(searchKeyword.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      })
      .slice(itemOffset, itemOffset + itemsPerPage);
    setCurrentItems(newDataArray);
  };

  return (
    <div>
      <main className="page-content">
        <div className="d-flex flex-column col-12">
          <div
            className="shadow-none w-100"
            style={{ height: "max-content" }}
          ></div>
          <div className="stackPagination">
            <div className="row">
              <h2 className="col">View All Issues</h2>
              <input
                className="col"
                style={{
                  margin: 10,
                  marginRight: 0,
                }}
                type="text"
                placeholder="Search Keyword..."
                onChange={(e) => filterSearchResult(e)}
                value={searchKeyword}
              />
              {currentItems.length ? (
                <IssuesFormTable
                  currentItems={currentItems}
                  itemOffset={itemOffset}
                />
              ) : (
                <>
                  <h2>No Issue Form Submitted</h2>
                </>
              )}
              <ReactPagination
                setCurrentItems={setCurrentItems}
                allDataArray={filteredData}
                itemsPerPage={itemsPerPage}
                allData={allIssueList}
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewIssuesPage;
