import React from "react";
import Footer from "../../components/footer/index.tsx";
import Navbar from "../../components/navbar/index.tsx";

const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
