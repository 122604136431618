import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditorQuill = ({ value, handleChangeEditor }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "align",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <div>
      <div>
        <ReactQuill
          value={value}
          modules={modules}
          formats={formats}
          onChange={handleChangeEditor}
        />
      </div>
    </div>
  );
};

export default TextEditorQuill;
