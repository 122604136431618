import React from "react";

const CommonButton = ({
  text,
  stylediv,
  fontSizebtncommon,
  svgicon,
  handleClick,
}) => {
  return (
    <button
      className={`btn_common_ d-flex justify-content-between align-items-center ${stylediv}`}
      onClick={handleClick}
    >
      <h6 className={` ${fontSizebtncommon} me-2 mb-0`}>{text}</h6>
      <div className="div_svg_common_btn">{svgicon}</div>
    </button>
  );
};

export default CommonButton;
