import React, { useState } from "react";
// Layout
import Layout from "../layouts/index.tsx";
// Sections
import SectionServiceProvider from "../../sections/serviceProvider/index.tsx";
import PopularServices from "../../sections/popularServices/index.tsx";
// Styles
import "../../assets/css/landingPage.css";
import ServiceWeProvide from "../../sections/serviceWeProvide/index.tsx";
import OurBlogs from "../../sections/ourBlogs/index.tsx";
import ContactUsModal from "../../modals/contactUsModal/index.tsx";
import { submitContactUsForm } from "../../helpers/api-util.ts";
import makeToast from "../../components/toast/index.tsx";

const LandingPage = () => {
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    phone_number: "",
    postal_code: "",
    message: "",
    service: "",
  });
  const [loaderContact, setLoaderContact] = useState(false);
  const [showSecond, setShowSecond] = useState(false);

  const handleChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "phone_number") {
      setInputValue({ ...inputValue, [name]: value.replace(/\D/g, "") });
    } else {
      setInputValue({ ...inputValue, [name]: value });
    }
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    setLoaderContact(true);
    // console.log("INPUT VALUES ==> ", inputValue);
    if (
      inputValue.name !== "" &&
      inputValue.email !== "" &&
      inputValue.phone_number !== "" &&
      inputValue.postal_code !== "" &&
      inputValue.message !== "" &&
      inputValue.service !== ""
    ) {
      submitContactUsForm(inputValue)
        .then((res) => {
          console.log("[RES] : SUBMIT CONTACT US FORM ==> ", res);
          setLoaderContact(false);
          makeToast(
            "success",
            "Your form was submitted succesfully. We'll contact you shortly!"
          );
          setInputValue({
            name: "",
            email: "",
            phone_number: "",
            postal_code: "",
            message: "",
            service: "",
          });
          setShowSecond(false);
        })
        .catch((err) => {
          console.log("[ERR] : SUBMIT CONTACT US FORM ==> ", err);
          setLoaderContact(false);

          makeToast("error", "Something went wrong. Try again later!");
        });
    } else {
      setLoaderContact(false);
      makeToast("error", "Kindly fill all the fields.");
    }
  };

  const submitBtnHandler = (bannerValues) => {
    setInputValue({ ...inputValue, service: bannerValues.userInput });
    setShowSecond(true);
  };
  return (
    <Layout>
      <SectionServiceProvider clickHandler={submitBtnHandler} />
      <div className="container div_container_wrapper">
        <PopularServices />
        <ServiceWeProvide />
        <OurBlogs />
      </div>
      <ContactUsModal
        onClose={() => {
          setShowSecond(false);
        }}
        showSecond={showSecond}
        handleChangeValue={handleChangeValue}
        inputValue={inputValue}
        handleSendEmail={handleSendEmail}
        loaderContact={loaderContact}
      />
    </Layout>
  );
};

export default LandingPage;
