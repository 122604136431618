import React from "react";

const CommonInput = ({
  type,
  name,
  placeholder,
  inputstylecontactus,
  value,
  handleChangeValue,
}) => {
  return (
    <div>
      <input
        className={`input_field_contact w-400 ${
          inputstylecontactus
            ? inputstylecontactus
            : "input_field_contact_padding"
        }`}
        type={`${type}`}
        id={`${name}`}
        name={`${name}`}
        placeholder={`${placeholder}`}
        value={value}
        onChange={handleChangeValue}
      />
    </div>
  );
};

export default CommonInput;
