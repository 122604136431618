import React, { useEffect, useState } from "react";
import CommonTabs from "../../components/commonTabs/index.tsx";
import CommonTabContent from "../../components/commonTabsContent/index.tsx";
import ContactUsModal from "../../modals/contactUsModal/index.tsx";

// IMAGES
import our_services_girdimage15 from "../../assets/images/our_services_girdimage15.png";
import our_services_girdimage14 from "../../assets/images/our_services_girdimage14.png";
import our_services_girdimage13 from "../../assets/images/our_services_girdimage13.png";
import our_services_girdimage12 from "../../assets/images/our_services_girdimage12.png";
import our_services_girdimage11 from "../../assets/images/our_services_girdimage11.png";
import our_services_girdimage10 from "../../assets/images/our_services_girdimage10.png";

import our_services_girdimage9 from "../../assets/images/our_services_girdimage9.png";
import our_services_girdimage8 from "../../assets/images/our_services_girdimage8.png";
import our_services_girdimage7 from "../../assets/images/our_services_girdimage7.png";
import our_services_girdimage6 from "../../assets/images/our_services_girdimage6.png";
import our_services_girdimage5 from "../../assets/images/our_services_girdimage5.png";
import our_services_girdimage4 from "../../assets/images/our_services_girdimage4.png";
import our_services_girdimage3 from "../../assets/images/our_services_girdimage3.png";
import our_services_girdimage2 from "../../assets/images/our_services_girdimage2.png";
import our_services_girdimage1 from "../../assets/images/our_services_girdimage1.png";

import toiletrepiar from "../../assets/images/ToiletRepair.png";
import ResealingSilicone from "../../assets/images/ResealingSilicone.png";
import BoilerRepair from "../../assets/images/BoilerRepair.png";
import KitchenAppliances from "../../assets/images/KitchenAppliances.png";
import Leakage from "../../assets/images/Leakage.png";
import Boiler from "../../assets/images/Boiler.png";
import BathroomAppliances from "../../assets/images/BathroomAppliances.png";
import TapInstallationReplacement from "../../assets/images/TapInstallationReplacement.png";
import SanifloMaceratorRepair from "../../assets/images/SanifloMaceratorRepair.png";

import Carpentry from "../../assets/images/Carpentry.png";
import Painting from "../../assets/images/Painting.png";
import Plastering from "../../assets/images/Plastering.png";
import Tiling from "../../assets/images/Tiling.png";
import Wallpapering from "../../assets/images/Wallpapering.png";

import AppliancesRepair from "../../assets/images/AppliancesRepair.png";
import BathroomRenovation from "../../assets/images/BathroomRenovation.png";
import GutterLeakandLeakInspection from "../../assets/images/GutterLeakandLeakInspection.png";
import LocksmithServices from "../../assets/images/LocksmithServices.png";
import SashDraughtProofing from "../../assets/images/SashDraughtProofing.png";
import WindowRepairServices from "../../assets/images/WindowRepairServices.png";
import { submitContactUsForm } from "../../helpers/api-util.ts";
import makeToast from "../../components/toast/index.tsx";

const SectionOurServices = ({ datafromHome }) => {
  const tabsHeadingOurServices = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Gas & Heating",
    },
    {
      id: 3,
      title: "Plumbing",
    },
    {
      id: 4,
      title: "Painter",
    },
    {
      id: 5,
      title: "Electrical",
    },
    {
      id: 6,
      title: "Handyman",
    },
    {
      id: 7,
      title: "Carpenter",
    },
    {
      id: 8,
      title: "Tiling",
    },
  ];

  const arraydata_navbar = [
    {
      id: 1,
      title: "Unblocking Toilets",
      img: our_services_girdimage3,
    },
    {
      id: 2,
      title: "Boiler Breakdown",
      img: our_services_girdimage4,
    },
    {
      id: 3,
      title: "Toilets Repair",
      img: toiletrepiar,
    },

    {
      id: 4,
      title: "Boiler Repair",
      img: BoilerRepair,
    },
    {
      id: 5,
      title: "Saniflo/Macerator Repair",
      img: SanifloMaceratorRepair,
    },

    {
      id: 6,
      title: "Water Leak Repair",
      // img: our_services_girdimage6,
      img: Leakage,
    },
    {
      id: 7,
      title: "Shower Installation Services",
      img: our_services_girdimage5,
    },
    {
      id: 8,
      title: "Resealing Silicone/Mastic",
      img: ResealingSilicone,
    },
    {
      id: 9,
      title: "Bathroom Appliances",
      img: BathroomAppliances,
    },
    {
      id: 10,
      title: "Tap Installation & Replacement",
      img: TapInstallationReplacement,
    },
    {
      id: 11,
      title: "Boiler",
      img: Boiler,
    },
    {
      id: 12,
      title: "Kitchen Appliances",
      img: KitchenAppliances,
    },
    {
      id: 13,
      title: "Leakage",
      img: Leakage,
    },
    {
      id: 14,
      title: "Others",
    },
    {
      id: 15,
      title: "An Emergency",
    },
  ];

  const arraydata_navabr_gasheating = [
    {
      id: 1,
      title: "Gas Safety Certificate",
      img: our_services_girdimage7,
    },
    {
      id: 2,
      title: "CO & Smoke Alarms",
      img: our_services_girdimage2,
    },
    {
      id: 3,
      title: "Gas Leak Repairs",
      img: our_services_girdimage1,
    },
    {
      id: 4,
      title: "Boiler Repairs",
      img: our_services_girdimage1,
    },
  ];

  const tabsContentOurServices = [
    {
      id: 1,
      title: "Boiler Repairs",
      img: our_services_girdimage1,
    },
    {
      id: 2,
      title: "CO & Smoke Alarms",
      img: our_services_girdimage2,
    },
    {
      id: 3,
      title: "Unblocking Toilets",
      img: our_services_girdimage3,
    },
    {
      id: 4,
      title: "Boiler Breakdown",
      img: our_services_girdimage4,
    },
    {
      id: 5,
      title: "Shower Installation Services",
      img: our_services_girdimage5,
    },
    {
      id: 6,
      title: "Water Leak Repair",
      img: our_services_girdimage6,
    },
    {
      id: 7,
      title: "Gas Safety Certificate",
      img: our_services_girdimage7,
    },
    {
      id: 8,
      title: "Gutter Clean & Leak Inspection",
      img: our_services_girdimage8,
    },
    {
      id: 9,
      title: "Carpentery",
      img: our_services_girdimage9,
    },
    {
      id: 10,
      title: "Tiling",
      img: our_services_girdimage10,
    },
    {
      id: 11,
      title: "Safety Checks & Others",
      img: our_services_girdimage11,
    },
    {
      id: 12,
      title: "Furniture Assembly Service",
      img: our_services_girdimage12,
    },
    {
      id: 13,
      title: "Fence Repair Service",
      img: our_services_girdimage13,
    },
    {
      id: 14,
      title: "Plastering Services",
      img: our_services_girdimage14,
    },
    {
      id: 15,
      title: "Alarm System & Security",
      img: our_services_girdimage15,
    },
  ];

  const arraydata_navabr_painter = [
    {
      id: 1,
      title: "Painting",
      img: Painting,
    },
    {
      id: 2,
      title: "Wallpapering",
      img: Wallpapering,
    },
    {
      id: 3,
      title: "Plastering",
      img: Plastering,
    },
    {
      id: 4,
      title: "Carpentry",
      img: Carpentry,
    },
    {
      id: 5,
      title: "Tiling",
      img: Tiling,
    },
    {
      id: 6,
      title: "Other",
    },
  ];

  const arraydata_navabr_electerician = [
    {
      id: 1,
      title: "An Emergency",
    },
    {
      id: 2,
      title: "Boiler",
      img: Boiler,
    },
    {
      id: 3,
      title: "Bathroom",
      img: BathroomAppliances,
    },
    {
      id: 4,
      title: "Kitchen appliances",
      img: KitchenAppliances,
    },
    {
      id: 5,
      title: "Leaks ",
      img: Leakage,
    },
    {
      id: 6,
      title: "Other",
    },
  ];

  const arraydata_navabr_handyman = [
    {
      id: 1,
      title: "Gutter Clean & Leak Inspection",
      img: GutterLeakandLeakInspection,
    },
    {
      id: 2,
      title: "Sash Draught Proofing",
      img: SashDraughtProofing,
    },
    {
      id: 3,
      title: "Draft Proofing External Doors",
    },
    {
      id: 4,
      title: "Furniture Assembly Service",
    },
    {
      id: 5,
      title: "Appliances Repairs",
      img: AppliancesRepair,
    },
    {
      id: 6,
      title: "Locksmith Services",
      img: LocksmithServices,
    },
    {
      id: 7,
      title: "Plastering Services",
      img: Plastering,
    },
    {
      id: 7,
      title: "Window Repair Services",
      img: WindowRepairServices,
    },
    {
      id: 7,
      title: "Fence Repair Services",
      img: our_services_girdimage13,
    },
    {
      id: 7,
      title: "Carpentry Services",
      img: Carpentry,
    },
    {
      id: 7,
      title: "Tiler",
      img: Tiling,
    },
    {
      id: 7,
      title: "Bathroom Renovation",
      img: BathroomRenovation,
    },
  ];

  const arraydata_navabr_carpenter = [
    {
      id: 1,
      title: "Windows &doors",
      img: our_services_girdimage9,
    },
    {
      id: 2,
      title: "Kitchens",
    },
    {
      id: 3,
      title: "Flooring ",
    },
    {
      id: 4,
      title: "Other",
      img: our_services_girdimage9,
    },
  ];

  const arraydata_navabr_Tiling = [
    {
      id: 1,
      title: "Fit New Tiles",
      img: Tiling,
    },
    {
      id: 2,
      title: "Replace Tiles",
    },
    {
      id: 3,
      title: "Repair Tiles",
    },
    {
      id: 4,
      title: "Other",
    },
  ];

  const [activeTab, setActiveTab] = useState(1);
  const [loaderContact, setLoaderContact] = useState(false);
  const [showSecond, setShowSecond] = useState(false);

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    phone_number: "",
    postal_code: "",
    message: "",
    service: "",
  });

  const handleChangeValue = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "phone_number") {
      setInputValue({ ...inputValue, [name]: value.replace(/\D/g, "") });
    } else {
      setInputValue({ ...inputValue, [name]: value });
    }
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    setLoaderContact(true);
    console.log("INPUT VALUES ==> ", inputValue);
    if (
      inputValue.name !== "" &&
      inputValue.email !== "" &&
      inputValue.phone_number !== "" &&
      inputValue.postal_code !== "" &&
      inputValue.message !== "" &&
      inputValue.service !== ""
    ) {
      submitContactUsForm(inputValue)
        .then((res) => {
          console.log("[RES] : SUBMIT CONTACT US FORM ==> ", res);
          setLoaderContact(false);
          makeToast(
            "success",
            "Your form was submitted succesfully. We'll contact you shortly!"
          );
          setInputValue({
            name: "",
            email: "",
            phone_number: "",
            postal_code: "",
            message: "",
            service: "",
          });
          setShowSecond(false);
        })
        .catch((err) => {
          console.log("[ERR] : SUBMIT CONTACT US FORM ==> ", err);
          setLoaderContact(false);

          makeToast("error", "Something went wrong. Try again later!");
        });
    } else {
      setLoaderContact(false);
      makeToast("error", "Kindly fill all the fields.");
    }
  };

  const handleChangesTabs = async (e, item) => {
    setActiveTab(item?.id);
  };

  const handleOpenModal = (item) => {
    setInputValue({ ...inputValue, service: item?.title });
    setShowSecond(true);
  };

  const handleSetActiveTab = () => {
    if (datafromHome === "Gas & Heating") {
      setActiveTab(2);
    } else if (datafromHome === "Plumbing" || datafromHome === "Plumber") {
      setActiveTab(3);
    } else if (datafromHome === "Painter") {
      setActiveTab(4);
    } else if (
      datafromHome === "Electrical" ||
      datafromHome === "Electrician"
    ) {
      setActiveTab(5);
    } else if (datafromHome === "Handyman") {
      setActiveTab(6);
    } else if (datafromHome === "Carpenter") {
      setActiveTab(7);
    } else if (datafromHome === "Tiling") {
      setActiveTab(8);
    } else {
      setActiveTab(1);
    }
  };

  useEffect(() => {
    handleSetActiveTab();
  }, [datafromHome]);
  return (
    <div>
      <CommonTabs
        headingdata={tabsHeadingOurServices}
        handleChangesTabs={handleChangesTabs}
        activeTab={activeTab}
      />
      <div>
        <div className="mt-5">
          {activeTab === 1 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={arraydata_navbar}
            />
          ) : activeTab === 2 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={arraydata_navabr_gasheating}
            />
          ) : activeTab === 3 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={tabsContentOurServices}
            />
          ) : activeTab === 4 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={arraydata_navabr_painter}
            />
          ) : activeTab === 5 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={arraydata_navabr_electerician}
            />
          ) : activeTab === 6 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={arraydata_navabr_handyman}
            />
          ) : activeTab === 7 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={arraydata_navabr_carpenter}
            />
          ) : activeTab === 8 ? (
            <CommonTabContent
              handleOpenModal={handleOpenModal}
              data={arraydata_navabr_Tiling}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <ContactUsModal
        onClose={() => {
          setShowSecond(false);
        }}
        showSecond={showSecond}
        handleChangeValue={handleChangeValue}
        inputValue={inputValue}
        handleSendEmail={handleSendEmail}
        loaderContact={loaderContact}
      />
    </div>
  );
};

export default SectionOurServices;
