import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../layouts/index.tsx";
import CommonFirstSectionHeader from "../../components/commonFirstSectionHeader/index.tsx";
import RightSideCol from "../../components/rightSideCol/index.tsx";
import { getAllBlogs } from "../../helpers/api-util.ts";

const SingleBlogPage = () => {
  const location = useLocation();
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const { item, stateFrom } = location.state;
  const title = item.title ? item.title : "";
  const content = item.content
    ? item.content
    : "Content currently unavailable! Try again later.";

  useEffect(() => {
    getAllBlogs()
      .then((res) => {
        console.log("[RES] = GET ALL BLOGS ==> ", res);
        setLatestBlogs(res.slice(0, 3));
        setRelatedBlogs(res.slice(0, 3));
      })
      .catch((err) => {
        console.log("[ERR] - GET ALL BLOGS ==> ", err);
      });
  }, []);
  return (
    <>
      {stateFrom === "dashboard" ? (
        <div>
          <main className="page-content p-0">
            <div className="d-flex flex-column col-12">
              <div>
                <CommonFirstSectionHeader
                  data={{
                    text1: "HOME | OUR BLOGS",
                    title1: title,
                  }}
                  backgroundImage={"bg-image-header-ourblogs"}
                  backgroundImageUrl={item.image_url ? item.image_url : ""}
                  inputnotshow={true}
                  clickHandler={() => {}}
                />
              </div>

              <div className="container div_container_wrapper">
                <div className="div_commonwrapper ">
                  <div className="row mt-5 mb-5">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="col-12">
                        <div
                          dangerouslySetInnerHTML={{ __html: content }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <Layout>
          <div>
            <CommonFirstSectionHeader
              data={{
                text1: "HOME | OUR BLOGS",
                title1: title,
              }}
              backgroundImage={"bg-image-header-ourblogs"}
              backgroundImageUrl={item.image_url ? item.image_url : ""}
              inputnotshow={true}
              clickHandler={() => {}}
            />
          </div>

          <div className="container div_container_wrapper">
            <div className="div_commonwrapper ">
              <div className="row mt-5 mb-5">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-xl-8 col-lg-7">
                      <div dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                    <div className="col-xl-4 col-lg-5">
                      <div
                        className="d-flex flex-column"
                        style={{ gap: "70px" }}
                      >
                        <RightSideCol
                          title={"Recent Articles"}
                          data={relatedBlogs}
                        />
                        <RightSideCol
                          title={"Latest News"}
                          data={latestBlogs}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default SingleBlogPage;
