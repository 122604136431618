import React from "react";
import hammerimage from "../../assets/images/searchman.png";

import "../../assets/css/report.css";
import Layout from "../layouts/index.tsx";
import ReportAnIssueForm from "./reportAnIssueForm/index.tsx";
import CommonSectionHeader from "../../components/commonSectionHeader/index.tsx";

const ReportAndIssue = () => {
  return (
    <Layout>
      <div>
        <div className="div_commonwrapper mt-5 mb-5">
          <div className="row">
            <div className="col-lg-6">
              <section className="left_section_report mb-5">
                <div
                  className="mt-5 mb-5"
                  data-animation="zoomIn"
                  data-delay="0.6s"
                  data-duration="0.3s"
                >
                  <CommonSectionHeader
                    title={"Report An"}
                    text1={"Get In Touch"}
                    text2="Thank you for reaching out to us! Your inquiries and feedback are important to us. Feel free to drop us a message below, and our dedicated team will promptly get back to you. We look forward to connecting with you and ensuring your experience with our website is seamless and enjoyable."
                    span={"Issue"}
                    styletext2={"widthtext2report"}
                  />
                </div>

                <div>
                  <ReportAnIssueForm />
                </div>
              </section>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="row d-flex justify-content-center">
                <div className="col-10 ">
                  <section className="right_section_report mb-5">
                    <div className="div_image_right_section d-flex justify-content-end">
                      <img
                        className="image_hammer_report  ms-5"
                        src={hammerimage}
                        alt="Report issue"
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReportAndIssue;
