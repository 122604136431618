import React from "react";

const CommonSectionHeader = ({ title, text1, text2, span, styletext2 }) => {
  return (
    <>
      <div>
        <h6 className="w-400 color3e">{text1}</h6>
        <h3 className="w-600 color3e">
          {" "}
          {title} <span className="span_easy_step ">{span}</span>
        </h3>
        <h6 className={`div_text3 w-400  color3e mt-4 ${styletext2}`}>
          {text2}
        </h6>
      </div>
    </>
  );
};

export default CommonSectionHeader;
