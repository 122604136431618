import React from "react";
import "../../assets/css/ourBlogs.css";
import OurBlogsCarosal from "../../components/ourBlogsCarosal/index.tsx";

const OurBlogs = () => {
  return (
    <div className="main_div_section_ourblogs div_commonwrapper mb-5">
      <section className="our_blogs_sectionone">
        <div>
          <h6 className="w-400 color3e">Our Blogs</h6>
          <h3 className="w-600 color3e">
            Latest News From <span className="span_easy_step ">Our Blogs</span>
          </h3>
          <h6 className={`div_text3 w-400  color3e mt-4 widthtext2`}>
            Dive into a world of knowledge with our captivating blog posts,
            where insights meet inspiration. Unveil the latest trends, explore
            thought-provoking topics, and join the conversation that fuels
            minds. Discover the power of learning as we share stories that
            inform, entertain, and ignite curiosity.
          </h6>
        </div>
      </section>
      <section className="our_blogs_sectionSecond ">
        {/* <div> */}
        <OurBlogsCarosal />
        {/* </div> */}
      </section>
    </div>
  );
};

export default OurBlogs;
