import React from "react";
import Slider from "react-slick";
import CommonFirstSectionHeader from "../../components/commonFirstSectionHeader/index.tsx";

const SectionServiceProvider = ({ clickHandler }) => {
  const settings = {
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    // autoplay: true,
    // autoplaySpeed: 8000,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    {
      text1: "Our Services",
      title2: "Electrical",
      text2:
        "Safe and efficient electrical services to keep your property powered and functioning properly",
    },
    {
      text1: "Our Services",
      title2: "Handyman",
      text2:
        "Versatile handyman services for all your odd jobs, repairs, and maintenance needs",
    },
    {
      text1: "Our Services",
      title2: "Plumbing",
      text2:
        "Trusted plumbing services for all your needs, from leaky faucets to full-scale installations",
    },
    {
      text1: "Our Services",
      title2: "Gas & Heating",
      text2:
        "Reliable gas and heating services to keep your home or business comfortable and warm",
    },
    {
      text1: "Our Services",
      title2: "Painting & Decorating",
      text2:
        "Expert painting and decorating services to transform and beautify your space",
    },
    {
      text1: "Our Services",
      title2: "Appliances",
      text2:
        "High-quality home and kitchen appliances for your convenience and ease",
    },
    {
      text1: "Our Services",
      title2: "Safety",
      text2:
        "Combines air-source heat pump system that heating, cooling & heating.",
    },
    {
      text1: "Our Services",
      title2: "Commercial",
      text2:
        "Professional and efficient commercial services to enhance your business operations",
    },
  ];

  return (
    <div className="div_Start_Slider_service_provider">
      <Slider {...settings}>
        {images.map((item, index) => {
          return (
            <CommonFirstSectionHeader
              data={item}
              key={index}
              backgroundImage={
                item?.title2 === "Electrical"
                  ? "bg-image-header1"
                  : item?.title2 === "Handyman"
                  ? "bg-image-header2"
                  : item?.title2 === "Plumbing"
                  ? "bg-image-header3"
                  : item?.title2 === "Gas & Heating"
                  ? "bg-image-header4"
                  : item?.title2 === "Painting & Decorating"
                  ? "bg-image-header5"
                  : item?.title2 === "Appliances"
                  ? "bg-image-header6"
                  : item?.title2 === "Safety"
                  ? "bg-image-header7"
                  : item?.title2 === "Commercial"
                  ? "bg-image-header8"
                  : ""
              }
              inputnotshow={false}
              clickHandler={clickHandler}
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default SectionServiceProvider;
