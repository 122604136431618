import React, { useState } from "react";
import logoimage from "../../assets/images/logo.png";
import "../../assets/css/navbar.css";
import "../../assets/css/common.css";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [hover, setHover] = useState(false);
  const [showmobile, setShowmobile] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [activeTab, setActiveTab] = useState(1);
  const [openDropDown, setOpenDropDown] = useState(false);

  const tabHeadings__navbar = [
    {
      id: 1,
      title: "Plumber",
    },
    {
      id: 2,
      title: "Electrician",
    },
    {
      id: 3,
      title: "Gas & Heating",
    },
    {
      id: 4,
      title: "Painter",
    },
    {
      id: 5,
      title: "HandyMan",
    },
    {
      id: 6,
      title: "More",
    },
  ];

  const arraydata_navabr_electerician = [
    {
      id: 1,
      title: "An Emergency",
    },
    {
      id: 2,
      title: "Boiler",
    },
    {
      id: 3,
      title: "Bathroom",
    },
    {
      id: 4,
      title: "Kitchen appliances",
    },
    {
      id: 5,
      title: "Leaks ",
    },
    {
      id: 6,
      title: "Other",
    },
  ];

  const arraydata_navabr_gasheating = [
    {
      id: 1,
      title: "Gas Safety Certificate",
    },
    {
      id: 2,
      title: "CO & Smoke Alarms",
    },
    {
      id: 3,
      title: "Gas Leak Repairs",
    },
    {
      id: 4,
      title: "Boiler Repairs",
    },
  ];

  const arraydata_navabr_handyman = [
    {
      id: 1,
      title: "Gutter Clean & Leak Inspection",
    },
    {
      id: 2,
      title: "Sash Draught Proofing",
    },
    {
      id: 3,
      title: "Draft Proofing External Doors",
    },
    {
      id: 4,
      title: "Furniture Assembly Service",
    },
    {
      id: 5,
      title: "Appliances Repairs",
    },
    {
      id: 6,
      title: "Locksmith Services",
    },
    {
      id: 7,
      title: "Plastering Services",
    },
    {
      id: 7,
      title: "Window Repair Services",
    },
    {
      id: 7,
      title: "Fence Repair Services",
    },
    {
      id: 7,
      title: "Carpentry Services",
    },
    {
      id: 7,
      title: "Tiler",
    },
    {
      id: 7,
      title: "Bathroom Renovation",
    },
  ];

  const arraydata_navabr_painter = [
    {
      id: 1,
      title: "Painting",
    },
    {
      id: 2,
      title: "Wallpapering",
    },
    {
      id: 3,
      title: "Plastering",
    },
    {
      id: 4,
      title: "Carpentry",
    },
    {
      id: 5,
      title: "Tiling",
    },
    {
      id: 6,
      title: "Other",
    },
  ];

  const arraydata_navbar = [
    {
      id: 1,
      title: "Unblocking Toilets",
    },
    {
      id: 2,
      title: "Boiler Breakdown",
    },
    {
      id: 3,
      title: "Toilets Repair",
    },

    {
      id: 4,
      title: "Boiler Repair",
    },
    {
      id: 5,
      title: "Saniflo/Macerator Repair",
    },

    {
      id: 6,
      title: "Water Leak Repair",
    },
    {
      id: 7,
      title: "Shower Installation Services",
    },
    {
      id: 8,
      title: "Resealing Silicone/Mastic",
    },
    {
      id: 9,
      title: "Bathroom Appliances",
    },
    {
      id: 10,
      title: "Tap Installation & Replacement",
    },
    {
      id: 11,
      title: "Boiler",
    },
    {
      id: 12,
      title: "Kitchen Appliances",
    },
    {
      id: 13,
      title: "Leakage",
    },
    {
      id: 14,
      title: "Others",
    },
    {
      id: 15,
      title: "An Emergency",
    },
  ];

  const handleChangesTabs = (e: any, item: any) => {
    setActiveTab(item.id);
  };
  const navbararray = [
    // {
    //   id: 1,
    //   to: "/",
    //   title: "HOME",
    // },
    {
      id: 1,
      to: "/our-services",
      title: "OUR SERVICES",
    },
    // {
    //   id: 3,
    //   to: "/about-us",
    //   title: "ABOUT US",
    // },

    {
      id: 2,
      to: "/our-blogs",
      title: "OUR BLOGS",
    },
    // {
    //   id: 5,
    //   to: "/contactus",
    //   title: "CONTACT US",
    // },
    {
      id: 3,
      to: "/report",
      title: "REPORT AN ISSUE",
    },
  ];

  return (
    <>
      <div className="container div_container_wrapper">
        <nav
          id="mainHeader"
          className=" navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-between div_commonwrapper"
          style={{
            height: "75px",
            zIndex: 999,
          }}
        >
          <a className="navbar-brand" href="/">
            <img src={logoimage} alt="logo" className="img-logo" />
          </a>

          <button
            className="navbar-toggler btn_toggler_navmenu"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowmobile(!showmobile)}
            style={{ padding: "5px" }}
          >
            {showmobile ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ width: "30px", height: "30px" }}
              >
                <span className="div_span_cross_icon">X</span>
              </div>
            ) : (
              <span className="navbar-toggler-icon"></span>
            )}
          </button>
          <div
            className={`collapse navbar-collapse ${
              showmobile
                ? "showmobilemenu bg-light mySidenav"
                : "displaynonemobile"
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto w-100 div-ul-navbar ">
              {navbararray.map((item, index) => (
                <li
                  key={item.id}
                  className="nav-item  li-navbar-item "
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    setHover(true);
                    setHoverIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHover(false);
                  }}
                >
                  <NavLink
                    className={`nav-link ${
                      hover && index === hoverIndex
                        ? "activecolor"
                        : "non-activecolor"
                    }`}
                    to={item.to}
                    onClick={() => {
                      if (
                        item.title === "OUR SERVICES" &&
                        hoverIndex === index
                      ) {
                        setOpenDropDown(!openDropDown);
                      }
                    }}
                  >
                    {item.title}
                  </NavLink>
                  {openDropDown && item.title === "OUR SERVICES" ? (
                    <div className="div_drop_down_menu_services">
                      <div
                        className="container"
                        style={{ paddingLeft: "30px" }}
                      >
                        <div className="  pt-4 pb-3">
                          <div className="div_main_tabs_heading_navbar d-flex flex-column">
                            {tabHeadings__navbar?.map((item) => (
                              <div key={item.id}>
                                <div
                                  className={`div_tabs_heading`}
                                  key={item.id}
                                  onClick={(e) => handleChangesTabs(e, item)}
                                >
                                  <div className="d-flex">
                                    <h5
                                      className={`w-400 h4_text_tab_common text-center  ${
                                        activeTab === item.id
                                          ? "color29"
                                          : "color97"
                                      }`}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.title}
                                    </h5>
                                  </div>
                                </div>
                                {(item.title === "Plumber" ||
                                  item.title === "Electrician" ||
                                  item.title === "Painter" ||
                                  item.title === "Gas & Heating" ||
                                  item.title === "Electrician" ||
                                  item.title === "HandyMan" ||
                                  item.title === "More") &&
                                activeTab === item.id ? (
                                  <>
                                    <div className="mt-4">
                                      <div className="container">
                                        <div className="mb-4">
                                          <h5 className="w-500  h4_tabs_mage_menu color3e">
                                            What do you need a{" "}
                                            {activeTab === 1
                                              ? "Plumbing"
                                              : activeTab === 2
                                              ? "Electrician"
                                              : activeTab === 3
                                              ? "Gas & Heating"
                                              : activeTab === 4
                                              ? "Painter "
                                              : activeTab === 5
                                              ? "Handyman"
                                              : "more"}{" "}
                                            to help with?
                                          </h5>
                                        </div>
                                        <div className="row">
                                          {(activeTab === 1
                                            ? arraydata_navbar
                                            : activeTab === 2
                                            ? arraydata_navabr_electerician
                                            : activeTab === 3
                                            ? arraydata_navabr_gasheating
                                            : activeTab === 4
                                            ? arraydata_navabr_painter
                                            : activeTab === 5
                                            ? arraydata_navabr_handyman
                                            : arraydata_navbar
                                          )?.map((item) => (
                                            <div
                                              className="col-lg-4 col-md-6"
                                              key={item.id}
                                            >
                                              <NavLink
                                                to="/our-services"
                                                style={{
                                                  textDecoration: "none",
                                                  textTransform: "none",
                                                }}
                                              >
                                                <h6
                                                  className={`w-400 color3e hovertabcontentnavbar`}
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  {item.title}
                                                </h6>
                                              </NavLink>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    className="dropdown-menu dropdown_mega_menu  mt-0"
                    aria-labelledby="navbarDropdown"
                    style={{
                      display: `${
                        hover &&
                        item.title === "OUR SERVICES" &&
                        hoverIndex === index
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    <div className="container" style={{ paddingLeft: "30px" }}>
                      <div className="  pt-4 pb-3">
                        <div className="div_main_tabs_heading_navbar d-flex">
                          {tabHeadings__navbar?.map((item) => (
                            <div
                              className={`div_tabs_heading`}
                              key={item.id}
                              onClick={(e) => handleChangesTabs(e, item)}
                            >
                              <div className="div_h4_text_tab_common_navbar">
                                <h5
                                  className={`w-400 h4_text_tab_common text-center  ${
                                    activeTab === item.id
                                      ? "color29"
                                      : "color97"
                                  }`}
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.title}
                                </h5>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="container">
                          <div className="mb-4">
                            <h5 className="w-500  h4_tabs_mage_menu color3e">
                              What do you need a{" "}
                              {activeTab === 1
                                ? "Plumbing"
                                : activeTab === 2
                                ? "Electrician"
                                : activeTab === 3
                                ? "Gas & Heating"
                                : activeTab === 4
                                ? "Painter "
                                : activeTab === 5
                                ? "Handyman"
                                : "more"}{" "}
                              to help with?
                            </h5>
                          </div>
                          <div className="row">
                            {(activeTab === 1
                              ? arraydata_navbar
                              : activeTab === 2
                              ? arraydata_navabr_electerician
                              : activeTab === 3
                              ? arraydata_navabr_gasheating
                              : activeTab === 4
                              ? arraydata_navabr_painter
                              : activeTab === 5
                              ? arraydata_navabr_handyman
                              : arraydata_navbar
                            )?.map((item) => (
                              <div className="col-lg-4 col-md-6" key={item.id}>
                                <NavLink
                                  to="/our-services"
                                  style={{
                                    textDecoration: "none",
                                    textTransform: "none",
                                  }}
                                >
                                  <h6
                                    className={`w-400 color3e hovertabcontentnavbar`}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {item.title}
                                  </h6>
                                </NavLink>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
