import React, { useEffect, useState } from "react";
import ReactPagination from "../../../../components/reactPagination/index.tsx";
import ContactUsFormTable from "../../../../components/contactUsFormTable/index.tsx";
import { getAllContactUsForms } from "../../../../helpers/api-util.ts";
import { useAuth } from "../../../../context/AuthContext.tsx";

const ViewContactUsPage = () => {
  const [currentItems, setCurrentItems] = useState([]);
  const [allContactList, setAllContactList] = useState([]);
  const [filteredData, setFilteredData] = useState(allContactList);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");
  const itemsPerPage = 10;
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      getAllContactUsForms(token)
        .then((res) => {
          console.log("[RES] : GET ALL CONTACT FORMS ==> ", res);
          setCurrentItems(res);
          setAllContactList(res);
          setFilteredData(res);
        })
        .catch((err) => {
          console.log("[ERR] : GET ALL CONTACT FORMS ==> ", err);
        });
    }
  }, [token]);

  const filterSearchResult = (e) => {
    const searchKeyword = e.target.value;
    setSearchKeyword(searchKeyword);
    const newDataArray = filteredData
      .filter((data) => {
        if (
          // data.id.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          data.name?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          data.email?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          data.phone_number
            ?.toLowerCase()
            .includes(searchKeyword.toLowerCase()) ||
          data.postal_code
            ?.toLowerCase()
            .includes(searchKeyword.toLowerCase()) ||
          data.service?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          data.message?.toLowerCase().includes(searchKeyword.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      })
      .slice(itemOffset, itemOffset + itemsPerPage);
    setCurrentItems(newDataArray);
  };
  return (
    <div>
      <main className="page-content">
        <div className="d-flex flex-column col-12">
          <div className="shadow-none w-100" style={{ height: "max-content" }}>
            <div className="stackPagination">
              <div className="row">
                <h2 className="col">View All Contact Us Form</h2>
                <input
                  className="col"
                  style={{
                    margin: 10,
                    marginRight: 0,
                  }}
                  type="text"
                  placeholder="Search Keyword..."
                  onChange={(e) => filterSearchResult(e)}
                  value={searchKeyword}
                />
                {currentItems.length ? (
                  <ContactUsFormTable
                    currentItems={currentItems}
                    itemOffset={itemOffset}
                  />
                ) : (
                  <>
                    <h2>No Contact Form Submitted</h2>
                  </>
                )}
                <ReactPagination
                  setCurrentItems={setCurrentItems}
                  allDataArray={filteredData}
                  itemsPerPage={itemsPerPage}
                  allData={allContactList}
                  itemOffset={itemOffset}
                  setItemOffset={setItemOffset}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewContactUsPage;
