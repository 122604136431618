import React from "react";
import Layout from "../layouts/index.tsx";

const PrivacyAndPolicy = () => {
  return (
    <Layout>
      <section className="container div_container_wrapper">
        <div className="div_commonwrapper">
          <div className="row">
            <div className="mt-5 mb-5">
              <div className="">
                <div className="section-title ">
                  <div className="title-header mt-5">
                    <h3>Privacy Policy</h3>
                  </div>
                </div>
                <h6 className="w-400">
                  At Rendoor, we are committed to protecting the privacy of our
                  users. This Privacy Policy explains how we collect, use, and
                  safeguard your personal information on our website. By using
                  our website, you agree to the terms of this Privacy Policy.
                </h6>

                <div>
                  <h4>Information We Collect</h4>
                  <h6 className="mb-20 w-400">
                    {" "}
                    When you use our website, we may collect personal
                    information from you, such as your name, email address,
                    phone number, and other contact information. We collect this
                    information to provide you with our services, to better
                    understand your needs, and to improve our website.
                  </h6>
                  <h6 className="mb-20 w-400">
                    We also collect certain non-personal information, such as
                    your IP address, the type of browser you are using, and the
                    pages you visit on our website. We use this information to
                    improve our website and to analyze how our website is used.
                  </h6>
                </div>
                <div>
                  <h4>Cookies</h4>
                  <h6 className="mb-20 w-400">
                    {" "}
                    Our website uses cookies to enhance your browsing
                    experience. Cookies are small data files that are placed on
                    your device when you visit a website. We use cookies to
                    remember your preferences, to personalize your experience,
                    and to analyze how our website is used.
                  </h6>
                  <h6 className="mb-20 w-400">
                    You can control the use of cookies on our website through
                    your browser settings. However, please note that disabling
                    cookies may limit your ability to use certain features of
                    our website.
                  </h6>
                </div>
                <div>
                  <h4>Information Sharing</h4>
                  <h6 className="mb-20 w-400">
                    {" "}
                    We do not sell, trade, or otherwise transfer your personal
                    information to outside parties, except as described in this
                    Privacy Policy. We may share your personal information with
                    third-party service providers who assist us in operating our
                    website or in providing our services to you. These service
                    providers are required to protect your information and to
                    use it only for the purposes for which it was shared.
                  </h6>
                  <h6 className="mb-20 w-400">
                    We may also disclose your personal information if required
                    by law or to protect our rights, property, or safety, or the
                    rights, property, or safety of others.
                  </h6>
                </div>
                <div>
                  <h4>Security</h4>
                  <h6 className="mb-20 w-400">
                    {" "}
                    We take the security of your personal information seriously
                    and have implemented reasonable measures to protect it from
                    unauthorized access, use, or disclosure. However, no
                    security system is foolproof, and we cannot guarantee the
                    security of your personal information.
                  </h6>
                </div>
                <div>
                  <h4>Children's Privacy</h4>
                  <h6 className="mb-20 w-400">
                    {" "}
                    Our website is not intended for use by children under the
                    age of 13. We do not knowingly collect personal information
                    from children under the age of 13. If you are a parent or
                    guardian and you become aware that your child has provided
                    us with personal information, please contact us immediately.
                  </h6>
                </div>
                <div>
                  <h4>Changes to This Privacy Policy</h4>
                  <h6 className="mb-20 w-400">
                    {" "}
                    We reserve the right to update or modify this Privacy Policy
                    at any time. Any changes will be reflected on this page, and
                    we encourage you to review this page periodically to stay
                    informed about our privacy practices.
                  </h6>
                </div>

                <div>
                  <h4>Contact Us</h4>
                  <h6 className="mb-20 w-400">
                    If you have any questions or concerns about this Privacy
                    Policy or our privacy practices, please contact us at{" "}
                    <a href="mailto:contact@rendoor.co.uk">
                      contact@rendoor.co.uk
                    </a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyAndPolicy;
