import React from "react";
import AdminSidePanel from "../../components/adminSidePanel/index.tsx";
import "../../assets/css/admin.css";
const AdminLayout = ({ children }) => {
  return (
    <div className="wrapper">
      <AdminSidePanel />
      {children}
    </div>
  );
};

export default AdminLayout;
