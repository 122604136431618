import React, { useEffect, useState } from "react";
import CreateCategoryForm from "../../../../../components/createCategoryForm/index.tsx";
import ReactPagination from "../../../../../components/reactPagination/index.tsx";
import BlogCategoryTable from "../../../../../components/blogCategoryTable/index.tsx";
import "../../../../../assets/css/admin.css";
import {
  createCategory,
  deleteCategory,
  getAllCategories,
  updateCategory,
} from "../../../../../helpers/api-util.ts";
import { useAuth } from "../../../../../context/AuthContext.tsx";
import makeToast from "../../../../../components/toast/index.tsx";

const AddCategory = () => {
  const [loader, setLoader] = useState(false);
  const [delLoader, setDelLoader] = useState(false);
  const [delData, setDelData] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [isUpdate, setIsupdate] = useState(false);
  const [allCategoryList, setAllCategoryList] = useState([]);
  const [filteredData, setFilteredData] = useState(allCategoryList);
  const [currentItems, setCurrentItems] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const { token } = useAuth();

  const handleSubmit = () => {
    setLoader(true);
    if (categoryTitle !== "") {
      const data = {
        title: categoryTitle,
      };

      if (isUpdate) {
        console.log("UPDATE");
        updateCategory(token ? token : "", data, categoryId)
          .then((res) => {
            console.log("[RES] - UPDATE BLOG CAT ==> ", res);
            makeToast("success", "Blog category updated succesfully.");
            fetchAllCategroies();
            setLoader(false);
            setIsupdate(false);
            setCategoryTitle("");
          })
          .catch((err) => {
            console.log("[ERR] - UPDATE BLOG CAT ==> ", err);
            makeToast(
              "error",
              err.message ? err.message : "Try again! Something went wrong."
            );
            setIsupdate(false);
            setLoader(false);
            setCategoryTitle("");
          });
      } else {
        createCategory(token ? token : "", data)
          .then((res) => {
            console.log("[RES] - CREATE BLOG CAT ==> ", res);
            makeToast("success", "Blog category created succesfully.");
            fetchAllCategroies();
            setLoader(false);
            setIsupdate(false);
            setCategoryTitle("");
          })
          .catch((err) => {
            console.log("[ERR] - CREATE BLOG CAT ==> ", err);
            makeToast(
              "error",
              err.message ? err.message : "Try again! Something went wrong."
            );
            setIsupdate(false);
            setLoader(false);
            setCategoryTitle("");
          });
      }
    } else {
      makeToast("error", "Kindly fill all the fileds.");
      setLoader(false);
    }
  };

  const EditCategory = (item: any) => {
    setIsupdate(true);
    setCategoryId(item.id);
    setCategoryTitle(item?.title ? item?.title : "");
    window.scrollTo(0, 0);
  };

  const onDelete = (data: any) => {
    setDelData(data);
    setDelLoader(true);
    deleteCategory(token ? token : "", data.id)
      .then((res) => {
        console.log("[RES] - DELETE BLOG CAT ==> ", res);
        makeToast("success", "Blog category deleted succesfully.");
        fetchAllCategroies();
      })
      .catch((err) => {
        console.log("[ERR] - DELETE BLOG CAT ==> ", err);
        makeToast(
          "error",
          err.message ? err.message : "Try again! Something went wrong."
        );
      })
      .finally(() => {
        setDelLoader(false);
      });
  };

  const fetchAllCategroies = () => {
    getAllCategories()
      .then((res) => {
        console.log("[RES] - GET ALL BLOG CAT ==> ", res);
        setAllCategoryList(res);
        setFilteredData(res);
        setCurrentItems(res);
      })
      .catch((err) => {
        console.log("[ERR] - GET ALL BLOGS CAT ==> ", err);
        makeToast(
          "error",
          err.message ? err.message : "Try again! Something went wrong."
        );
      });
  };

  useEffect(() => {
    fetchAllCategroies();
  }, []);

  return (
    <div>
      <main className="page-content">
        <>
          <div className="d-none d-sm-flex align-items-center mb-3 "></div>
          <div className="card">
            <div className="card-header py-3">
              <h6 className="mb-0">Add Blog Category</h6>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-lg-4 d-flex">
                  <div
                    className="card border shadow-none w-100"
                    style={{ height: "max-content" }}
                  >
                    <CreateCategoryForm
                      handleSubmit={handleSubmit}
                      handleOnChange={setCategoryTitle}
                      categoryTitle={categoryTitle}
                      isUpdate={isUpdate}
                      loader={loader}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-8 d-flex">
                  <div className="card border shadow-none w-100">
                    <div className="card-body">
                      <BlogCategoryTable
                        currentItems={currentItems}
                        EditCategory={EditCategory}
                        onDelete={onDelete}
                        loader={delLoader}
                        delData={delData}
                        itemOffset={itemOffset}
                      />
                      <div className="stackPagination">
                        <ReactPagination
                          setCurrentItems={setCurrentItems}
                          allDataArray={filteredData}
                          itemsPerPage={itemsPerPage}
                          allData={allCategoryList}
                          itemOffset={itemOffset}
                          setItemOffset={setItemOffset}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </main>
    </div>
  );
};

export default AddCategory;
