import React, { Fragment, useState } from "react";
import "../../assets/css/autoComplete.css";

const AutoComplete = ({ suggestions, userInput, setUserInput }) => {
  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const onChange = (e) => {
    const currentUserInput = e.currentTarget.value;
    let currentFilteredSuggestions = [];
    if (currentUserInput === "") {
      currentFilteredSuggestions = suggestions;
    } else {
      currentFilteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(currentUserInput.toLowerCase()) > -1
      );
    }
    setActiveSuggestion(0);
    setFilteredSuggestions(currentFilteredSuggestions);
    setShowSuggestions(true);
    setUserInput(currentUserInput);
  };

  const onClick = (e) => {
    setActiveSuggestion(0);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
    setUserInput(e.currentTarget.innerText);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setActiveSuggestion(0);
      setShowSuggestions(false);
      setUserInput(filteredSuggestions[activeSuggestion]);
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  return (
    <Fragment>
      <input
        className="autocomplet-input"
        type="text"
        placeholder="What Service are you looking for?"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
      />
      {showSuggestions &&
        userInput &&
        (filteredSuggestions.length ? (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className: string = "";
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        ) : (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        ))}
    </Fragment>
  );
};

export default AutoComplete;
