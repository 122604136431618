import React from "react";
import "../../assets/css/footer.css";
import "../../assets/css/common.css";
import { NavLink } from "react-router-dom";

import facebook from "../../assets/images/facebook-svg.svg";
import instagram from "../../assets/images/instagram-svg.svg";
import linkedin from "../../assets/images/linkedin-svg.svg";
import twitter from "../../assets/images/twitter-svg.svg";

const SectionSecondFooter = () => {
  const footerDataSecond = [
    {
      title: "Company",
      menu: [
        {
          icon: "",
          menu: "Home",
          to: "/",
        },
        {
          icon: "",
          menu: "Our Services",
          to: "/our-services",
        },
        {
          icon: "",
          menu: "Our Blogs",
          to: "/our-blogs",
        },
        {
          icon: "",
          menu: "Contact us",
          to: "/contactus",
        },
        {
          icon: "",
          menu: "Report an issue",
          to: "/report",
        },
      ],
    },

    {
      title: "Our Services",
      menu: [
        {
          icon: "",
          menu: "Plumber",
          to: "/our-services",
        },
        {
          icon: "",
          menu: "Electrician",
          to: "/our-services",
        },
        {
          icon: "",
          menu: "Roofer",
          to: "/our-services",
        },
        {
          icon: "",
          menu: "Builder",
          to: "/our-services",
        },
        {
          icon: "",
          menu: "Gardener",
          to: "/our-services",
        },
        {
          icon: "",
          menu: "Painter",
          to: "/our-services",
        },
        {
          icon: "",
          menu: "Landscaper",
          to: "/our-services",
        },
      ],
    },
    {
      title: "Contact with us",
      menu: [
        {
          icon: facebook,
          menu: "Facebook",
          to: "https://www.facebook.com/",
        },
        {
          icon: instagram,
          menu: "Instagram",
          to: "https://www.instagram.com/accounts/login/",
        },
        {
          icon: linkedin,
          menu: "Linkedin",
          // to: "https://www.linkedin.com/in/muhammad-zia-ul-hassan-90a167175/",
          to: "https://www.linkedin.com/",
        },
        {
          icon: twitter,
          menu: "Twitter",
          to: "https://twitter.com/i/flow/login",
        },
      ],
    },
  ];
  return (
    <>
      <div className="div_sectionfooter_second  ">
        {footerDataSecond?.map((item, index) => {
          return (
            <div
              key={index}
              className={`${
                item.title === "Contact with us"
                  ? "div_col_footer_second_contatct"
                  : "div_col_footer_second_other"
              } col-lg-4 col-md-4 col-sm-4 col-xs-4 div_col_listitem_right_section`}
            >
              {item.title === "Contact with us" ? (
                <>
                  <h5 className="w-500 colorWhite mb-4">{item?.title}</h5>
                  <div className="d-flex flex-column">
                    <div className="row ">
                      {item.menu.map((item2, index2) => (
                        <div className="col-lg-6" key={index2}>
                          <div className="d-flex align-items-center mb-4 div_icon_menu_ttile">
                            {item2.icon !== "" ? (
                              <NavLink
                                to={
                                  item2.menu === "Twitter"
                                    ? "https://twitter.com/i/flow/login"
                                    : item2.menu === "Facebook"
                                    ? "https://www.facebook.com/"
                                    : item2.menu === "Instagram"
                                    ? "https://www.instagram.com/accounts/login/"
                                    : item2.menu === "Linkedin"
                                    ? "https://www.linkedin.com/in/muhammad-zia-ul-hassan-90a167175/"
                                    : ""
                                }
                              >
                                <div className="circel_icon_div me-3">
                                  {item2.menu === "Twitter" ? (
                                    <svg
                                      width="19"
                                      height="16"
                                      viewBox="0 0 19 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M18.4615 2.27577C17.775 2.57692 17.0435 2.77654 16.2808 2.87346C17.0654 2.405 17.6642 1.66885 17.9458 0.781538C17.2142 1.21769 16.4065 1.52577 15.5458 1.69769C14.8512 0.958077 13.8612 0.5 12.7812 0.5C10.6858 0.5 8.99885 2.20077 8.99885 4.28577C8.99885 4.58577 9.02423 4.87423 9.08654 5.14885C5.94 4.99538 3.15577 3.48731 1.28538 1.19C0.958846 1.75654 0.767308 2.405 0.767308 3.10308C0.767308 4.41385 1.44231 5.57577 2.44846 6.24846C1.84038 6.23692 1.24385 6.06038 0.738461 5.78231V5.82385C0.738461 7.66308 2.05038 9.19077 3.77077 9.54269C3.46269 9.62692 3.12692 9.66731 2.77846 9.66731C2.53615 9.66731 2.29154 9.65346 2.06192 9.60269C2.55231 11.1015 3.94385 12.2035 5.59846 12.2392C4.31077 13.2465 2.67577 13.8535 0.905769 13.8535C0.595385 13.8535 0.297692 13.8396 0 13.8015C1.67654 14.8827 3.66346 15.5 5.80615 15.5C12.7708 15.5 16.5785 9.73077 16.5785 4.73C16.5785 4.56269 16.5727 4.40115 16.5646 4.24077C17.3158 3.70769 17.9469 3.04192 18.4615 2.27577Z"
                                        fill="#F0F0F0"
                                      />
                                    </svg>
                                  ) : item2.menu === "Facebook" ? (
                                    <svg
                                      width="9"
                                      height="16"
                                      viewBox="0 0 9 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M2.25282 8.96683H0.394243C0.0938674 8.96683 0 8.85419 0 8.57259V6.301C0 6.00063 0.112641 5.90676 0.394243 5.90676H2.25282V4.25469C2.25282 3.50375 2.38423 2.79036 2.7597 2.13329C3.15394 1.45745 3.71715 1.00688 4.43054 0.744055C4.89987 0.575094 5.36921 0.5 5.87609 0.5H7.71589C7.97872 0.5 8.09136 0.612641 8.09136 0.875469V3.01564C8.09136 3.27847 7.97872 3.39111 7.71589 3.39111C7.20901 3.39111 6.70213 3.39111 6.19524 3.40989C5.68836 3.40989 5.42553 3.65394 5.42553 4.1796C5.40676 4.7428 5.42553 5.28723 5.42553 5.86921H7.60325C7.90363 5.86921 8.01627 5.98185 8.01627 6.28223V8.55382C8.01627 8.85419 7.9224 8.94806 7.60325 8.94806H5.42553V15.0682C5.42553 15.3874 5.33166 15.5 4.99374 15.5H2.64706C2.36546 15.5 2.25282 15.3874 2.25282 15.1058V8.96683Z"
                                        fill="#F0F0F0"
                                      />
                                    </svg>
                                  ) : item2.menu === "Instagram" ? (
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.388 0.5H4.61202C2.34465 0.5 0.5 2.34465 0.5 4.61205V11.388C0.5 13.6554 2.34465 15.5 4.61202 15.5H11.388C13.6554 15.5 15.5 13.6554 15.5 11.388V4.61205C15.5 2.34465 13.6554 0.5 11.388 0.5ZM8 12.1142C5.7314 12.1142 3.88581 10.2686 3.88581 8C3.88581 5.7314 5.73143 3.88584 8 3.88584C10.2686 3.88584 12.1142 5.73143 12.1142 8C12.1142 10.2686 10.2686 12.1142 8 12.1142ZM12.3603 4.88973C11.671 4.88973 11.1103 4.32898 11.1103 3.63975C11.1103 2.95051 11.671 2.38974 12.3603 2.38974C13.0495 2.38974 13.6103 2.95048 13.6103 3.63972C13.6103 4.32895 13.0495 4.88973 12.3603 4.88973Z"
                                        fill="#F0F0F0"
                                      />
                                    </svg>
                                  ) : item2.menu === "Linkedin" ? (
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.4885 15.4885V9.99615C15.4885 7.29615 14.9115 5.24231 11.7615 5.24231C10.25 5.24231 9.23462 6.06154 8.81923 6.85769H8.78462V5.48462H5.80769V15.4885H8.92308V10.5385C8.92308 9.22308 9.16539 7.97692 10.7923 7.97692C12.4192 7.97692 12.4077 9.47692 12.4077 10.6308V15.5L15.4885 15.4885ZM0.753846 5.48462H3.85769V15.4885H0.753846V5.48462ZM2.31154 0.5C1.31923 0.5 0.5 1.30769 0.5 2.3C0.5 3.29231 1.30769 4.11154 2.3 4.11154C3.29231 4.11154 4.11154 3.30385 4.11154 2.31154V2.28846C4.11154 1.30769 3.30385 0.5 2.31154 0.5Z"
                                        fill="#F0F0F0"
                                      />
                                    </svg>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </NavLink>
                            ) : (
                              ""
                            )}
                            <NavLink
                              to={item2?.to}
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <h6 className="w-400 colorWhite mb-0">
                                {item2.menu}
                              </h6>
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h5 className="w-500 colorWhite mb-4">{item?.title}</h5>
                  <div className="d-flex flex-column" style={{ gap: "15px" }}>
                    {item.menu.map((item2, index2) => (
                      <div
                        key={index2}
                        className="d-flex align-items-center mb-1 div_icon_menu_ttile"
                      >
                        <NavLink
                          to={item2?.to}
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <h6 className="w-400 colorWhite mb-0">
                            {item2.menu}
                          </h6>
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SectionSecondFooter;
