import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Ricon from "../../assets/images/logo.png";
import { useAuth } from "../../context/AuthContext.tsx";

const NavbarItem = ({ item }) => {
  const navigate = useNavigate();
  return (
    <li className="cursor-pointer">
      <ul>
        {item.menu?.map((menu, index) =>
          menu == null ? null : (
            <li key={index}>
              <a href="#" onClick={() => navigate(menu.to)}>
                {menu.icon}
                {menu.label}
              </a>
            </li>
          )
        )}
      </ul>
    </li>
  );
};

const AdminSidePanel = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [toggleOpen, setToggleOpen] = useState(false);
  const products = [
    {
      label: "Blogs",
      menu: [
        {
          icon: <i className="bi bi-circle" />,
          label: "View All Blogs",
          to: "/admin/blogs",
        },
        {
          icon: <i className="bi bi-circle"></i>,
          label: "Add Blog",
          to: "/admin/blogs/create-blog",
        },
      ],
    },
    {
      label: "Categories",
      menu: [
        {
          icon: <i className="bi bi-circle"></i>,
          label: "Add Category",
          to: "/admin/categories/create-category",
        },
      ],
    },
    {
      label: "Contact Form Data",
      menu: [
        {
          icon: <i className="bi bi-circle"></i>,
          label: "View All Froms",
          to: "/admin/contact_us_forms",
        },
      ],
    },
    {
      label: "Reported Issues",
      menu: [
        {
          icon: <i className="bi bi-circle"></i>,
          label: "View All Issues",
          to: "/admin/reported_issues",
        },
      ],
    },
  ];

  const logoutHandler = () => {
    // LogoutAPI Call
    navigate("/admin/login");
    logout();
  };

  return (
    <aside
      className={`sidebar-wrapper ${toggleOpen ? "toggled" : ""}`}
      data-simplebar="true"
    >
      {/* Header */}
      <div className="sidebar-header">
        <div className="sidebar-image">
          <img src={Ricon} className="logo-icon" alt="logo icon" />
        </div>

        <div
          className="toggle-icon ms-auto"
          onClick={() => {
            setToggleOpen(!toggleOpen);
          }}
        >
          {toggleOpen ? (
            <i className="bi bi-x-lg"></i>
          ) : (
            <i className="bi bi-list"></i>
          )}
        </div>
      </div>
      {/* Items */}

      <div
        className="d-flex flex-column justify-content-between menu-list"
        style={{ height: "100vh" }}
      >
        <div>
          <ul className="metismenu" id="menu">
            <NavbarItem
              item={{
                label: "Dashboard",
                icon: <i className="bx bx-home"></i>,
                to: "/admin/dashboard",
              }}
            />
            {products?.map((item, index) => (
              <>
                {item.label && <li className="menu-label">{item.label}</li>}
                <NavbarItem key={index} item={item} />
              </>
            ))}
          </ul>
        </div>
        <button
          type="button"
          className="div_logout p-2 m-2"
          onClick={logoutHandler}
        >
          Logout
        </button>
      </div>
    </aside>
  );
};

export default AdminSidePanel;
