import React from "react";

const CommonTabs = ({ headingdata, handleChangesTabs, activeTab }) => {
  return (
    <div className="div_main_tabs_heading d-flex">
      {headingdata?.map((item) => (
        <div
          className={`div_tabs_heading`}
          key={item.id}
          onClick={(e) => handleChangesTabs(e, item)}
        >
          <div className="div_h4_text_tab_common">
            <h5
              className={`h5_text_tab w-400 _common text-center  ${
                activeTab === item.id ? "color29" : "color97"
              }`}
            >
              {item.title}
            </h5>
          </div>

          <div className={`${activeTab === item.id ? "div_line_hl" : ""} `}>
            <span className={`${activeTab === item.id ? "hl" : ""} `}></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommonTabs;
