import React from "react";
import testImage from "../../assets/images/Boiler.png";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ data, dashboard = true, onDelete }) => {
  const navigate = useNavigate();

  const removeTags = (str: string, removeFor: string) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    const charatersToFetch = removeFor === "content" ? 500 : 200;

    return str.replace(/(<([^>]+)>)/gi, "").slice(0, charatersToFetch) + "...";
  };

  return (
    <div
      className={`${
        dashboard ? "col-lg-6 col-sm-12" : ""
      } p-3 mb-3 d-flex flex-column justify-content-between div_card_follow div_blog_card`}
      onClick={
        dashboard
          ? undefined
          : () => {
              navigate(`${data.id}`, {
                state: {
                  item: data,
                },
              });
            }
      }
      style={!dashboard ? { cursor: "pointer" } : undefined}
    >
      <div>
        <div className="div_image_card_topleft_text">
          <img
            className="image_blog_card"
            src={
              data.image_url
                ? `${process.env.REACT_APP_BACKEND_URL}${data.image_url}`
                : testImage
            }
            alt="image_not_available"
          />
        </div>
        <h3 className="mt-4 mb-0">{`Title: ${data.title}`}</h3>
        <div className="color3e">
          <strong>Description: </strong>
          {data.short_description
            ? removeTags(data.short_description, "desc")
            : "N/A"}
        </div>
        <div>
          <strong>Content: </strong>
          {removeTags(data.content, "content")}
        </div>
        {dashboard && (
          <div className="d-flex flex-row mt-2">
            <div className="col text-center">
              <button
                type="button"
                className="col btn btn-outline-primary w-75"
                onClick={() => {
                  navigate(`${data.id}`, {
                    state: {
                      item: data,
                      stateFrom: "dashboard",
                    },
                  });
                }}
              >
                <i className="bi bi-eye"></i> Show
              </button>
            </div>
            <div className="col text-center">
              <button
                type="button"
                className="col btn btn-outline-info w-75"
                onClick={() => {
                  navigate(`edit/${data.id}`, {
                    state: {
                      item: data,
                      id: data.id,
                    },
                  });
                }}
              >
                <i className="bi bi-pencil"></i> Edit
              </button>
            </div>
            <div className="col text-center">
              <button
                type="button"
                className="col btn btn-outline-danger w-75"
                onClick={onDelete ? () => onDelete(data.id) : undefined}
              >
                <i className="bi bi-trash"></i> Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogCard;
