import AdminLogin from "../pages/admin/adminLogin.tsx";
import AddBlog from "../pages/admin/adminPages/blogs/createBlogs/index.tsx";
import AddCategory from "../pages/admin/adminPages/categories/createCategories/index.tsx";
import ViewBlogList from "../pages/admin/adminPages/blogs/viewBlogs/index.tsx";
import ViewCategoryList from "../pages/admin/adminPages/categories/viewCategories/index.tsx";
import AdminDashboard from "../pages/admin/adminPages/dashboard/index.tsx";
import LandingPage from "../pages/landingPage/index.tsx";
import OurBlogsPage from "../pages/ourBlogs/index.tsx";
import OurServicePage from "../pages/ourServices/index.tsx";
import SingleBlogPage from "../pages/singleBlogPage/index.tsx";
import ContactUs from "../pages/contactUs/index.tsx";
import ReportAndIssue from "../pages/reportAnIssue/index.tsx";
import PrivacyAndPolicy from "../pages/privayPolicy/index.tsx";
import ViewContactUsPage from "../pages/admin/adminPages/viewContactUs/index.tsx";
import ViewIssuesPage from "../pages/admin/adminPages/viewIssues/index.tsx";

export const protectedRoutes = [
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
  },
  {
    path: "/admin/blogs",
    element: <ViewBlogList />,
  },
  { path: "/admin/blogs/create-blog", element: <AddBlog /> },
  { path: "/admin/blogs/:id", element: <SingleBlogPage /> },
  { path: "/admin/blogs/edit/:id", element: <AddBlog /> },
  {
    path: "/admin/categories",
    element: <ViewCategoryList />,
  },
  {
    path: "/admin/categories/create-category",
    element: <AddCategory />,
  },
  {
    path: "/admin/contact_us_forms",
    element: <ViewContactUsPage />,
  },
  {
    path: "/admin/reported_issues",
    element: <ViewIssuesPage />,
  },
];

export const publicRoutes = [
  { path: "/", element: <LandingPage /> },
  { path: "/our-services", element: <OurServicePage /> },
  { path: "/our-blogs", element: <OurBlogsPage /> },
  { path: "/our-blogs/:id", element: <SingleBlogPage /> },
  { path: "/admin/login", element: <AdminLogin /> },
  { path: "/contactus", element: <ContactUs /> },
  { path: "/report", element: <ReportAndIssue /> },
  { path: "/privacy-policy", element: <PrivacyAndPolicy /> },
  // { path: "/our-services", element: <OurServices /> },
  // { path: "/about-us", element: <AboutUs /> },
  // { path: "/our-blogs", element: <OurBlogs /> },
  // { path: "/our-blogs-next", element: <BlogNextPage /> },
  // { path: "/admin/login", element: <Adminlogin /> },
];
